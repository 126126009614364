import axiosInstance from "../../../api/axiosInstance";

// Service to manage scheduled calls
const ScheduledCallsService = {
  // Fetch all scheduled calls
  getAll: async () => {
    const response = await axiosInstance.get("/scheduled-calls");
    return response.data;
  },

  get: async (application_id) => {
    const response = await axiosInstance.get(`/scheduled-calls/${application_id}`);
    return response.data;
  },

  getImagesForApplication: async (application_id) => {
    const response = await axiosInstance.get(`/uploads/${application_id}`);
    return response.data;
  },

  generateCallToken: async (application_id,data) => {
    const response = await axiosInstance.put(`/scheduled-calls/${application_id}/generate-call-token`);
    return response.data;
  },

  createRecording: async (application_id) => {
    const response = await axiosInstance.put(`/scheduled-calls/${application_id}/create-recording`);
    return response.data;
  },

  // Create a new scheduled call
  create: async (data) => {
    const response = await axiosInstance.post("/scheduled-calls", data);
    return response.data;
  },

  uploadImage: async (application_id,data) => {
    const response = await axiosInstance.post(`/upload/${application_id}`, data);
    return response.data;
  },

  // Update a scheduled call by ID
  update: async (application_id, data) => {
    const response = await axiosInstance.put(`/scheduled-calls/${application_id}`, data);
    return response.data;
  },

  updateSchedule: async (application_id, data) => {
    const response = await axiosInstance.put(`/scheduled-calls/${application_id}/schedule`, data);
    return response.data;
  },
  updateStatusToCompleted: async (application_id, data) => {
    const response = await axiosInstance.put(`/scheduled-calls/${application_id}/completed`, data);
    return response.data;
  },

  updateStatusToCallEnded: async (application_id) => {
    const response = await axiosInstance.put(`/scheduled-calls/${application_id}/call-ended`);
    return response.data;
  },

  // Delete a scheduled call by ID
  delete: async (application_id) => {
    const response = await axiosInstance.delete(`/scheduled-calls/${application_id}`);
    return response.data;
  },
};

export default ScheduledCallsService;
