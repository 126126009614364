import React, { useEffect, useRef, useState } from "react";
import { connect, createLocalTracks } from "twilio-video";

const Video = ({ token, roomName, endCall, mode, endCallTrigger,facingMode }) => {
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const [room, setRoom] = useState(null);
  const [localVideoTrack, setLocalVideoTrack] = useState(null);

  const attachTrack = (track, container) => {
    container.appendChild(track.attach());
  };

  const detachTrack = (track) => {
    track.detach().forEach((element) => element.remove());
  };
  const stopVideo = () => {
    if (localVideoTrack) {
      localVideoTrack.stop(); // Stops the camera
      localVideoTrack.detach().forEach((element) => element.remove()); // Removes video elements
      setLocalVideoTrack(null);
    }
  };


  useEffect(() => {
    let roomInstance;

    if (endCallTrigger) {
      handleEndCall();
      return;
    }

    stopVideo();

    const handleParticipantConnected = (participant) => {
      console.log("Participant connected:", participant.identity);
  
      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          attachTrack(publication.track, remoteVideoRef.current);
        }
  
        publication.on("subscribed", (track) => {
          attachTrack(track, remoteVideoRef.current);
        });
  
        publication.on("unsubscribed", (track) => {
          detachTrack(track);
        });
      });
  
      participant.on("trackSubscribed", (track) => {
        attachTrack(track, remoteVideoRef.current);
      });
  
      participant.on("trackUnsubscribed", (track) => {
        detachTrack(track);
      });
    };
  
    const handleParticipantDisconnected = (participant) => {
      console.log("Participant disconnected:", participant.identity);
      participant.tracks.forEach((publication) => {
        if (publication.track) {
          detachTrack(publication.track);
        }
      });
    };

 

    connect(token, { name: roomName, tracks: [] }).then((connectedRoom) => {
      roomInstance = connectedRoom;
      setRoom(roomInstance);




      createLocalTracks({
        audio: true,
        video: { facingMode,width: 640, height:  window.screen.height/3 },
      }).then((localTracks) => {
                // Publish local tracks to the room
                localTracks.forEach((track) => {
                  roomInstance.localParticipant.publishTrack(track);
                  setLocalVideoTrack(track);
                  if (track.kind === "video") {
                    attachTrack(track, localVideoRef.current);
                  }
                });
      });
   
      roomInstance.participants.forEach(handleParticipantConnected);
      roomInstance.on("participantConnected", handleParticipantConnected);
      roomInstance.on("participantDisconnected", handleParticipantDisconnected);
    });

    return () => {
      if (roomInstance) {
        roomInstance.disconnect();
        roomInstance.localParticipant.tracks.forEach((publication) => {
          publication.track.stop();
        });
      }
    };
  }, [token, roomName, endCallTrigger,facingMode]);

  const handleEndCall = () => {
    if (room) {
      // Detach and stop local video
      room.localParticipant.tracks.forEach((publication) => {
        const track = publication.track;
        if (track) {
          track.stop();
          detachTrack(track);
        }
      });

      // Detach and clear remote video
      room.participants.forEach((participant) => {
        participant.tracks.forEach((publication) => {
          const track = publication.track;
          if (track) {
            detachTrack(track);
          }
        });
      });

      room.disconnect(); // Disconnect the room
      setRoom(null); // Reset the room state
      endCall();
    }
    window.location.href = "/lobby?application_id=" + roomName;
  };

  return (
    <div
      className={`flex ${
        mode === "portrait" ? "flex-col" : "flex-row"
      } items-center justify-center w-full h-full shadow-md relative`}
    >
      {/* Remote Video */}
      <div
        style={{
          border: "5px solid white",
          height:`${window.screen.height/3}px`,
          width: mode === "portrait" ? "100%" : "640px",
        }}
        ref={remoteVideoRef}
        className="w-10/12 h-5/5 bg-gray-300 relative"
      >
        {/* Positioned Div for Local Video */}
        {mode === "portrait" && (
          <div
            className="absolute top-4 right-4 w-1/4 h-1/4 bg-gray-400 overflow-hidden rounded-lg"
            style={{ border: "2px solid #fff" }}
          >
            <div
              ref={localVideoRef}
              className="w-full h-full"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Video;
