import React, { useState, useEffect } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import "react-datetime/css/react-datetime.css"; // Import the CSS for the datetime picker
import "./VideoCallLobby.css"
import ScheduledCallsService from "../features/scheduledCalls/services/ScheduledCallsService";
import LocationAccess from "../components/LocationAccess";
import CameraAccess from "../components/CameraAccess";
 

const VideoCallLobby = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
   


    const navigate = useNavigate();
    
  const [loanApplication, setLoanApplication] = useState([]);
 
  const [locationAccess, setLocationAccess] = useState(false);
  const [cameraAccess, setCameraAccess] = useState(false);

  const [status, setStatus] = useState([]);

   
  const requestMediaPermissions = async () => {
    try {
      // Request camera and microphone access
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setStatus((prevStatus) => [
        ...prevStatus,
        "Camera and audio permissions granted.",
      ]);
      // Stop the stream after access for resource efficiency
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      setStatus((prevStatus) => [
        ...prevStatus,
        `Camera/audio permission denied: ${error.message}`,
      ]);
    }
  };

  const handleJoinNow = async (record) => {
    const callToken = record.call_token || (await ScheduledCallsService.generateCallToken(record.application_id, {})).applicant_call_token;
    navigate(`/video-interview?room_id=${record.application_id}`);
};
 

  const formatScheduledCall = (from, till) => {
    return (
      moment(from).format("Do MMM YY, hh:mm a") +
      " to " +
      moment(till).format("hh:mm a")
    );
  };

  const fetchScheduledCall = async (applicationId) => {
    try {
      const data = await ScheduledCallsService.get(applicationId);
      setLoanApplication(data);
      console.log(moment())
      console.log(moment(data.call_scheduled_till))
      if( moment().isAfter(moment(data.call_scheduled_till))){
        console.log("Call Done")
         
      }
    } catch (err) {
      console.error(err);
    }
  };


 
 


  useEffect(() => {
    const application_id = queryParams.get('application_id');
    console.log(application_id)
    fetchScheduledCall(application_id);
    requestMediaPermissions();
    
   // requestLocationPermission();
  }, []);

  if(!locationAccess){
    return <LocationAccess setLocationAccess={setLocationAccess}/>
  }if(!cameraAccess){
    return <CameraAccess setCameraAccess={setCameraAccess}/>
  }
  return (
    <div style={styles.container}>
      <div style={styles.card}>

      <br/>
      <h1 style={styles.loading}><strong>Applicant: &nbsp;</strong>  {loanApplication.applicant_name}</h1>
      <br/>
      <h1 style={styles.loading}><strong>Device Permissions &nbsp;</strong></h1>
      
      <h1 style={styles.loading}>  Location Access &nbsp;<span style={styles.okTick}>✔</span></h1>
      <h1 style={styles.loading}>  Camera Access &nbsp;<span style={styles.okTick}>✔</span></h1>
      <div className="lobby-details">
      

        <br/>
        <br/>
        <div className="detail">
          <strong>Call Schedule:</strong><br/>{formatScheduledCall(loanApplication.call_scheduled_from,loanApplication.call_scheduled_till)}
        </div>
 
       </div>

       {loanApplication?.status=="Call Ended" && <h3 style={{color:'red'}}>Call Ended</h3>}
       {!['Completed','Call Ended'].includes(loanApplication?.status) && loanApplication.call_scheduled_from ? (
                ( moment(loanApplication.call_scheduled_from).isBefore(moment()) &&  moment(loanApplication.call_scheduled_till).isAfter(moment()) ) && (
                      // If the scheduled call is in the past, format the date
                      <><br/>
                      <button
                        onClick={() => handleJoinNow(loanApplication)}
                         className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                      >
                        Join Now
                      </button></>
                    )
                  ) : ''}

{loanApplication.call_scheduled_from ? (
                ( moment(loanApplication.call_scheduled_from).isAfter(moment())) && (
                      // If the scheduled call is in the past, format the date
                
                        <center style={{color:'blue'}}>Please re-visit this page on {moment(loanApplication.call_scheduled_from).format("Do MMM YY, hh:mm a")}. </center>
                      
                    )
                  ) : ''}
    </div>
    </div>
  );
};


const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "linear-gradient(135deg, #673ab7, #512da8)",
    fontFamily: "'Inter', sans-serif",
    padding: "20px",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "16px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "24px",
    width: "100%",
    maxWidth: "400px",
    textAlign: "center",
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#ff4d4d",
    marginBottom: "16px",
  },
  description: {
    fontSize: "16px",
    color: "#555",
    marginBottom: "24px",
  },
  instructions: {
    textAlign: "left",
    fontSize: "16px",
    color: "#333",
    lineHeight: "1.5",
  },
  subHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "12px",
    color: "#444",
  },
  loading: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#444",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  okTick: {
    color: "#4caf50",
    fontSize: "24px",
    marginRight: "8px",
  },
};

export default VideoCallLobby;
