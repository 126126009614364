import React, { useState,useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css"; // Import the CSS for the datetime picker
import LoanApplicationsService from "../features/loanApplications/services/LoanApplicationsService";
import ScheduledCallsService from "../features/scheduledCalls/services/ScheduledCallsService";
import AuthService from '../features/auth/services/AuthService';
import Utils from '../features/loanApplications/Utils';
import Notification from "../partials/Notification";
import IncomeEligibility from "../pages/IncomeEligibility/IncomeEligibility";
import moment from "moment";
import RepVideoInterview from "./RepVideoInterview";
import PhotoGallery from "./PhotoGallery/PhotoGallery";
import CustomDatePicker from "../partials/CustomDatePicker";



const Field = ({ field, onChange, value,onFileUpload }) => {
    const handleValidation = (val) => {
        if (field.validations) {
            for (const validation of field.validations) {
                if (validation.name === "regex") {
                    const regex = new RegExp(validation.config.regex);
                    if (!regex.test(val)) {
                        return validation.message;
                    }
                }
            }
        }
        return null;
    };

    const handleFileUpload = (file)=> {
      onFileUpload(file);
    }

    const handleChange = (e) => {
        const newValue = e.target.type === "checkbox" ? (e.target.checked ? "1" : "0") : e.target.value;
        const error = handleValidation(newValue);
        onChange(field.name, newValue, error);
    };

   

    switch (field.ui_ele_type) {
        case 9:
            return (
                <div className="flex items-center mb-4">
                    <input
                        type="checkbox"
                        checked={value === "1"}
                        onChange={handleChange}
                        className="h-6 w-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <label className="ml-3 text-lg font-semibold text-gray-700">
                        {field.label}  {field.required ? <span className="text-red-500">*</span>:""}
                    </label>
                </div>
            );

            case 15:
            return (
                <div className="mb-6">
    <label className="block text-lg font-semibold text-gray-700">
        {field.label} {field.required ? <span className="text-red-500">*</span>:""}
    </label>
    <textarea
        placeholder="Comment"
        value={value || ""}
        onChange={handleChange}
        rows="4"
        className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
    ></textarea>
</div>
            );
        case 1:
            return (
                <div className="mb-6">
                    <label className="block text-lg font-semibold text-gray-700">
                        {field.label}  {field.required ? <span className="text-red-500">*</span>:""}
                    </label>
                    <input
                        type="text"
                        value={value || ""}
                        onChange={handleChange}
                        className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
                        placeholder={`Enter ${field.label}`}
                    />
                </div>
            );
        case 2:
            return (
                <div className="mb-6">
                    <label className="block text-lg font-semibold text-gray-700">
                        {field.label}  {field.required ? <span className="text-red-500">*</span>:""}
                    </label>
                    <select
                        value={value || ""}
                        onChange={handleChange}
                        className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
                    >
                        <option value="">Select {field.label}</option>
                        {field.options.map((opt, idx) => (
                            <option key={idx} value={opt.value}>
                                {opt.label}
                            </option>
                        ))}
                    </select>
                </div>
            );
        case 5:
            return (
                <div className="mb-6">
                    <label className="block text-lg font-semibold text-gray-700">
                        {field.label}
                    </label>
                    <input
                        type="file"
                        onChange={handleFileUpload}
                        className="mt-2 block w-full text-lg text-gray-500 file:mr-4 file:rounded-lg file:border-0 file:bg-blue-50 file:py-3 file:px-6 file:text-lg file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
                    />
                </div>
            );
        case 10:
            return (
                <div className="mb-6">
                    <label className="block text-lg font-semibold text-gray-700">
                        {field.label}  {field.required ? <span className="text-red-500">*</span>:""}
                    </label>
                    <CustomDatePicker value={value || ""} onDateChange={handleChange}/>
                  
                </div>
            );
        default:
            return null;
    }
};

const FormComponent = ({ formData }) => {
    const incomeEligibilityRef = useRef()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [formValues, setFormValues] = useState({});
    const [applicationId, setApplicationId] = useState(null);
    const [globalId, setGlobalId] = useState(null);
    const [section, setSection] = useState({});
    const [errors, setErrors] = useState({});
    const [arrayFields, setArrayFields] = useState({});
    const [schedule,setSchedule] = useState(null)
    const [notification, setNotification] = useState(null);
    const [photoId, setPhotoId] = useState(null);

    const showNotification = (message, type) => {
        setNotification({ message, type });
    };

    const handleSuccess = (msg) => {
        showNotification(msg, "success");
    };

    const handleError = (msg) => {
        showNotification(msg, "error");
    };

    const fetchApplication = async (applicationId) => {
        try {
          const [applicationRes,err] = await LoanApplicationsService.get(applicationId);

          const sections = applicationRes?.data?.application.sections 

          setGlobalId(applicationRes?.data?.application.global_id)
    
          if(formData.name=="income_eligibility"){
            return;
          }
    
 
          for(let i=0;i<sections.length;i++){
            if(sections[i].form_id ==formData._id ){
      
                for(let k in sections[i].data){
                    if(sections[i].data[k]?.type=="array"){
       
                        const items = sections[i].data[k]?.value
                        let frm = [];
                        for(let j in items){
                            let frmObj = {};
                             
                        const values = items[j].value
                        for(let k in values){
                      
                            frmObj[k] = values[k].value
                        }
                        frm.push(frmObj)
                        }
                       
                        setFormValues((prev) => (frm));
                        if(formData.name=="co_borrower_details"){
                            setArrayFields((prev) => ({"co_borrower":frm}));
                        }else if(['neighborhood_references'].includes(formData.name)){
         
                            setArrayFields((prev) => ({[formData.name]:frm}));
                        }else if('trade_references'==formData.name){
                       
                          setArrayFields((prev) => ({['business_relation']:frm}));
                      }

                    }else{
                        const value = sections[i].data[k].value
                        setFormValues((prev) => ({ ...prev, [k]: value }));
                    }
                

                }
                break;
            }
            
          }
        } catch (err) {
          console.error(err);
        }
      };
      
      useEffect(() => {
        setFormValues(null);
        const application_id = queryParams.get('application_id');
        setApplicationId(application_id);
        fetchApplication(application_id);
        fetchSchedule(application_id);
      }, [formData]);


    const handleFieldChange = (name, value, error) => {
        setFormValues((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: error }));
    };

    const handleArrayFieldChange = (arrayName, index, fieldName, value, error) => {
        setFormValues((prevValues) => {
            const updatedArray = [...prevValues || []];
            updatedArray[index] = {
                ...(updatedArray[index] || {}),
                [fieldName]: value,
            };
            return updatedArray;
        });
    };
 
    const handleAddField = (fieldName) => {
        setArrayFields((prev) => ({
            ...prev,
            [fieldName]: prev[fieldName] ? [...prev[fieldName], {}] : [{}],
        }));
        
    };


    const fetchSchedule = async (applicationId) => {
        try {
          const scheduleRes = await ScheduledCallsService.get(applicationId);

          setSchedule(scheduleRes)
        } catch (err) {
          console.error(err);
        }
      };
      
      const onFileUpload = async (dataUri) => {
    
        const fileUploadTokenRes = await AuthService.getToken()
        
        try {
          const blob = await fetch(dataUri).then((res) => res.blob());
          const formData = new FormData();
          formData.append("file", blob, "file.jpg");

  
          formData.append("file_type", "borrower_details");
          formData.append("org_id", localStorage.getItem("org_id"));
          formData.append("global_id", globalId);
          formData.append("token", fileUploadTokenRes.data.token);

          
    
          const [fileUploadRes,fileUploadErr] = await LoanApplicationsService.uploadFile(formData);
          if(!fileUploadErr){
            setFormValues((prev) => ({ ...prev, borrower_photo: fileUploadRes?.data?.application_file?._id }));
          }


        } catch (error) {
          console.error("Error uploading photo:", error);
        } finally {
           
        }
      };
      
    const handleSubmit = async (e)  =>  {
        e.preventDefault();
        
        const hasErrors = Object.values(errors).some((error) => error);
        if (!hasErrors) {
            /*switch(formData.name){
                case "borrower_details":
                   
                    break;
            }*/
                
                    if(formData.name=="borrower_details" && !formValues.borrower_photo ){
                        formValues.borrower_photo = "test"
                    }
                    let data = null;
                    if(formData.name=="co_borrower_details"){
                        const formKey ="co_borrower"
                        data = {
                            [formKey]:formValues
                        }
                    }else if(formData.name=="trade_references"){
                        const formKey ="business_relation"
                        data = {
                            [formKey]:formValues
                        }
                    }else if(formData.name=="neighborhood_references"){
                      const formKey ="neighborhood_references"
                      data = {
                          [formKey]:formValues
                      }
                  }else if(formData.name=="videopd_photos"){
                    const formKey ="photos"
                    let photos = schedule.uploadedImages.map((p)=>{
                      return {
                          "photo": p.entity_id,
                          "tag": p.tag
                      }
                    })
                    data = {
                        [formKey]:photos
                    }
     
                }else if(formData.name=="income_eligibility" && incomeEligibilityRef.current){
                    data = 
                       incomeEligibilityRef.current.getIncomeSources()
                    
                }else{
                        data = formValues
                    }
                    const payload = {
                        "id": applicationId,
                        "form_id": Utils.getFormId(formData.name),
                        "data":{
                          ...data
                        }
                    }
                   const [saveRes,saveErr]= await LoanApplicationsService.save(payload)
                   if(saveErr){
                    handleError(saveErr)
                   }else{
                    handleSuccess("Application Updated!")
                   }
        } else {
            console.log("Errors in the form:", errors);
        }
    };

    if(formData.name=="business_pd"){
      return <div>
      <h3 className="text-2xl font-bold text-gray-900">{formData.label}</h3>
      {formData.fields.map((field, index) => (
        <div key={index}>
          <h2>{field.title}</h2>
          
            {field.labels.map((label, idx) => (
              <div style={{marginTop:'10pt'}}>
              <h2 className="text-1xl font-bold text-gray-800" key={idx}>{label.title}</h2>
              <ol>
              {label.text.map((t, idx2) => (
                <li key={idx2}>{idx2+1}. {t}</li>
              ))}
                </ol>
                </div>
            ))}
            
        </div>
      ))}
    </div>
    }
    
    return (
        <>
        <form onSubmit={handleSubmit} className="space-y-6">
            <h3 className="text-2xl font-bold text-gray-900">{formData.label}</h3>
            {formData?.name=="income_eligibility" && <IncomeEligibility ref={incomeEligibilityRef} formData={formData} /> }
            {formData?.name=="videopd_photos" && <><PhotoGallery photos={schedule.uploadedImages} /></> }
            {!["videopd_photos","income_eligibility"].includes(formData?.name) && formData.fields.map((field, idx) => (
                <>
                
                    { field.type !== "array" && (
                        <Field
                            key={idx}
                            field={field}
                            value={formValues?.[field.name]}
                            onChange={handleFieldChange}
                            onFileUpload={onFileUpload}
                        />
                    )}
                    {field.type === "array" &&
                        arrayFields[field.name]?.map((_, idx2) => (
                            <><div key={`${idx}_${idx2}`} className="space-y-4">
                                {field.property.properties.map((property, idx3) => (
                                       <Field
                                       key={`${idx}_${idx2}_${idx3}`}
                                       field={property}
                                       value={formValues?.[idx2]?.[property.name] || ""}
                                       onChange={(name, value, error) =>
                                           handleArrayFieldChange(field.name, idx2, name, value, error)
                                       }
                                   />
                                ))}
                            </div>
                            <div style={{ border: "1px dotted black",height:"2px",width : "100%"}}></div>
                            </>
                        ))}
                    {field.type === "array" && (
                        <button
                            type="button"
                            onClick={() => handleAddField(field.name)}
                            className="text-blue-600 hover:text-blue-800"
                        >
                            Add 
                        </button>
                    )}
                </>
            ))}
            {Object.values(errors).some((error) => error) && (
                <div className="p-4 mb-6 text-lg text-red-700 bg-red-100 rounded-lg" role="alert">
                    <ul>
                        {Object.entries(errors)
                            .filter(([, error]) => error)
                            .map(([field, error], idx) => (
                                <li key={idx}>{`${field}: ${error}`}</li>
                            ))}
                    </ul>
                </div>
            )}
            {schedule?.status!='Completed' &&  <button
                type="submit"
                className="w-full px-6 py-3 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
            >
                Submit
            </button> }
           
        </form>
        {notification && (
        <Notification
        message={notification?.message}
        type={notification?.type}
        onClose={() => setNotification(null)}
        
    />)}
    </>
    );
};

const VideoCallSection = ({applicationId}) => {

    const [schedule,setSchedule] = useState(null)
    const [isVideoPdTimeNow,setIsVideoPdTimeNow] = useState(false)
    const fetchSchedule = async (applicationId) => {
        try {
          const scheduleRes = await ScheduledCallsService.get(applicationId);
          if(['Scheduled','Joined'].includes(scheduleRes.status)  && scheduleRes.call_scheduled_from && !scheduleRes?.rep_call_token){
            if(new Date().getTime()>=new Date(scheduleRes.call_scheduled_from) && new Date().getTime()<=new Date(scheduleRes.call_scheduled_till) ){
              const callToken =   (await ScheduledCallsService.generateCallToken(applicationId, {})).rep_call_token;
              scheduleRes.rep_call_token = callToken
              setIsVideoPdTimeNow(true)
              
            }
          }else if(['Scheduled','Joined'].includes(scheduleRes.status) && scheduleRes?.rep_call_token){
            setIsVideoPdTimeNow(true)
          }

          setSchedule(scheduleRes)
          
        } catch (err) {
          console.error(err);
        }
      };


    useEffect(() => {
       // alert(applicationId)
       setInterval(function(){
        fetchSchedule(applicationId);
       },5000)

      }, []);

      const endCall = async ()=> {
        await ScheduledCallsService.updateStatusToCallEnded(applicationId);
        fetchSchedule(applicationId);
      }

      if(schedule?.status=="Call Ended"){
        return (
            <div className="flex items-center justify-center h-full">
              <div className="bg-yellow-100 border-l-4 border-yellow-500 p-6 max-w-md w-full rounded-lg shadow-md">
                <div className="flex items-center space-x-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 text-yellow-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 8v4.5l3.5-1.5M9.5 9.5L12 8m0 0L14.5 9.5m-3 9.5h4a4 4 0 100-8h-4a4 4 0 000 8z"
                    />
                  </svg>
                  <p className="text-lg font-semibold text-gray-800">
                    Video PD Call Ended.
                  </p>
                </div>
              </div>
            </div>
          );
      }
      else if(schedule?.status=="Pending"){
        return (
            <div className="flex items-center justify-center min-h-screen">
              <div className="bg-yellow-100 border-l-4 border-yellow-500 p-6 max-w-md w-full rounded-lg shadow-md">
                <div className="flex items-center space-x-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 text-yellow-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 8v4.5l3.5-1.5M9.5 9.5L12 8m0 0L14.5 9.5m-3 9.5h4a4 4 0 100-8h-4a4 4 0 000 8z"
                    />
                  </svg>
                  <p className="text-lg font-semibold text-gray-800">
                    Video PD is not yet Scheduled
                  </p>
                </div>
              </div>
            </div>
          );
      }
      else if(schedule?.status=="Scheduled"){
        return (
            <div className="flex items-center justify-center min-h-screen">
              <div className="bg-yellow-100 border-l-4 border-yellow-500 p-6 max-w-md w-full rounded-lg shadow-md">
                <div className="flex items-center space-x-4">
     
                  <p className="text-lg font-semibold text-gray-800">
                  {schedule.call_scheduled_from ? (
            ( moment(schedule.call_scheduled_from).isAfter(moment())) && (
                  // If the scheduled call is in the past, format the date
            
                    <center style={{color:'blue'}}>Video PD Scheduled @ {moment(schedule.call_scheduled_from).format("Do MMM YY, hh:mm a")}. </center>
                  
                )
                
                
              ) : ''}
                  </p>
                </div>
              </div>
            </div>
          );
       
      }
      else if(isVideoPdTimeNow){
        return <><RepVideoInterview endCall={endCall} callToken={schedule?.rep_call_token} roomName={applicationId} /></>
      }




      }

const VerticalTabs = ({ application,applicationId,forms }) => {
    const [borrowerName] = useState(application?.sections?.find(section => section?.name === "borrower_details")?.data?.name?.value || "")
    const [activeTab, setActiveTab] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [notification,setNotification] = useState(null)
    const [fromDateTime, setFromDateTime] = useState(moment());
    const [tillDateTime, setTillDateTime] = useState(moment().add(1, "hour"));
   if(!forms?.length){
    return <></>
   }

   const handleCloseApplication = async ()=> {
    try {
        const y = window.confirm("Are you sure to close this Loan Application?")
        if(y){
          await LoanApplicationsService.closeVisit(applicationId)
          await ScheduledCallsService.updateStatusToCompleted(applicationId);
          setNotification({ message:"Application Closed.", type:"success" });
        }
      } catch (err) {
        console.error(err);
      }
   }



   const handleScheduleCall = async () => {
      setShowModal(false);

      // Persist the schedule to the backend
      try {
         await ScheduledCallsService.updateSchedule(applicationId, {
          call_scheduled_from: fromDateTime,
          call_scheduled_till: tillDateTime,
        });
 
const startTime = new Date(new Date(fromDateTime).getTime() + 330 * 60000);
const endTime = new Date(new Date(tillDateTime).getTime() + 330 * 60000);
        const [saveRes,saveErr]= await LoanApplicationsService.sendVideoPDLink({
            "application_id":applicationId,
            "start_time":startTime,
            "end_time":endTime,
            "video_pd_link":`${process.env.REACT_APP_URL}/lobby?application_id=${applicationId}`
        })
        
        setNotification({ message:"Video PD Scheduled", type:"success" });
 
      } catch (error) {
        console.error("Error scheduling call:", error);

        setNotification({ message:"Failed to schedule the call.", type:"error" });
      }
    
  };

    return (
<div className="flex h-[calc(100vh-95px)]">
  {/* Menu Section */}
  <div className="w-1/5 bg-gray-200 p-4 overflow-y-auto">
    <div className="flex flex-col items-center">
      <img src={`/user.png`} alt="Logo" className="w-16 mb-4 rounded-full" />
      <span className="text-lg font-semibold">{borrowerName}</span>
    </div>
    {forms.map((form, idx) => (
      !["crif_details","business_photos","family_photos","collateral_photos"].includes(form.name) && <div
        key={idx}
        onClick={() => setActiveTab(idx)}
        className={`cursor-pointer px-4 py-2 border rounded-md text-center ${
          activeTab === idx ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800"
        }`}
      >
        {form.label}
      </div>
    ))}
    <div
        key={'schedule_video_pd'}
        onClick={() => setShowModal(true)}
        className={`cursor-pointer px-4 py-1 border rounded-md text-center ${
          activeTab === 'schedule_video_pd' ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800"
        }`}
      >
        Schedule Video PD 
      </div>
     
      <div
        key={'close_application'}
        onClick={handleCloseApplication}
        className={`cursor-pointer px-4 py-1 border rounded-md text-center ${
          activeTab === 'close_application' ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800"
        }`}
      >
        Close Application
      </div>

  </div>

  {/* Form Section */}
  <div style={{backgroundColor:"#f5f5f58f"}} className="w-2/5 p-6 overflow-y-auto">
    <FormComponent formData={forms[activeTab]} />
  </div>

  {/* Video Section */}
  <div style={{backgroundColor:"#E5E7EB"}} className="w-2/5 sticky top-0 ">
    <VideoCallSection applicationId={applicationId} />
  </div>

  {notification && (
        <Notification
        message={notification?.message}
        type={notification?.type}
        onClose={() => setNotification(null)}/>
  )}
  {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm">
            <h3 className="text-lg font-semibold mb-4">Schedule Video PD</h3>
            <div className="mb-4">
              <label className="block mb-2 text-sm">From:</label>
              <Datetime
                value={fromDateTime}
                onChange={(date) => setFromDateTime(moment(date))}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-sm">Till:</label>
              <Datetime
                value={tillDateTime}
                onChange={(date) => setTillDateTime(moment(date))}
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500 mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleScheduleCall}
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
              >
                Schedule
              </button>
            </div>
          </div>
        </div>
      )}
</div>


      
    );
};

const LoanApplicationDetails = () => {
    const [forms,setForms] = useState([]);
    const [application,setApplication] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
   


    const fetchApplication = async (applicationId) => {
        try {
          const [applicationRes,err] = await LoanApplicationsService.get(applicationId);
     
          setForms(applicationRes?.data?.application?.forms)
          setApplication(applicationRes?.data?.application)
        } catch (err) {
          console.error(err);
        }
      };


    useEffect(() => {
        const application_id = queryParams.get('application_id');
        fetchApplication(application_id);
      }, []);

    return <VerticalTabs application={application} applicationId={application?.id} forms={forms} />;
};

export default LoanApplicationDetails;
