import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

// Helper function to format string dates to JavaScript Date object
const formattedDate = (d) => {
  if (!d) {
    return null; // Return null if no date is provided
  }
  try {
    const split = d.split("/");
    if (split.length !== 3) throw new Error("Invalid date format");
    const date = new Date(`${split[2]}-${split[1]}-${split[0]}`);
    if (isNaN(date.getTime())) throw new Error("Invalid date");
    return date;
  } catch (error) {
    console.error("Error parsing date:", error);
    return null;
  }
};

const CustomDatePicker = ({ onDateChange, value }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  // Sync initial date from value prop
  useEffect(() => {
    const date = formattedDate(value);
    setSelectedDate(date);
  }, [value]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedValue = date ? format(date, "dd/MM/yyyy") : "";
    onDateChange({
      type: "date",
      target: {
        value: formattedValue,
      },
    }); // Pass the formatted date to the parent
  };

  return (
    
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        placeholderText="Select a date"
        className="border rounded-md p-2 text-gray-800"
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        maxDate={new Date()}
      />
 
  );
};

export default CustomDatePicker;
