import React, { useEffect, useRef, useState } from "react";
import { connect, createLocalTracks } from "twilio-video";
import "react-html5-camera-photo/build/css/index.css";

const RepVideoInterview = ({ callToken, roomName,endCall }) => {
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const [room, setRoom] = useState(null);

  const attachTrack = (track, container) => {
    container.appendChild(track.attach());
  };

  const detachTrack = (track) => {
    track.detach().forEach((element) => element.remove());
  };

  useEffect(() => {
    const handleParticipantConnected = (participant) => {
      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          attachTrack(publication.track, remoteVideoRef.current);
        }
      });

      participant.on("trackSubscribed", (track) => {
        attachTrack(track, remoteVideoRef.current);
      });

      participant.on("trackUnsubscribed", (track) => {
        detachTrack(track);
      });
    };

    let roomInstance;

    connect(callToken, { name: roomName, tracks: [] }).then((connectedRoom) => {
      roomInstance = connectedRoom;
      setRoom(roomInstance);

      createLocalTracks({ audio: true, video: { width: 640, height: 480 } }).then((localTracks) => {
        localTracks.forEach((track) => {
          roomInstance.localParticipant.publishTrack(track);
          if (track.kind === "video") {
            attachTrack(track, localVideoRef.current);
          }
        });
      });

      roomInstance.participants.forEach(handleParticipantConnected);
      roomInstance.on("participantConnected", handleParticipantConnected);
    });

    return () => {
      if (roomInstance) {
        roomInstance.disconnect();
        roomInstance.localParticipant.tracks.forEach((publication) => {
          publication.track.stop();
        });
      }
    };
  }, [callToken, roomName]);

  const handleEndCall = () => {
    if (room) {
      // Detach and stop local video
      room.localParticipant.tracks.forEach((publication) => {
        const track = publication.track;
        if (track) {
          track.stop();
          detachTrack(track);
          
        }
      });

      // Detach and clear remote video
      room.participants.forEach((participant) => {
        participant.tracks.forEach((publication) => {
          const track = publication.track;
          if (track) {
            detachTrack(track);
          }
        });
      });

      room.disconnect(); // Disconnect the room
      setRoom(null); // Reset the room state
      endCall();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full shadow-md relative">
    {/* Remote Video */}
    <center>
    <div style={{border:"5px solid white",width:"640px",height:"640px"}} ref={remoteVideoRef} className="w-10/12 h-5/5 bg-gray-300 relative">
      {/* Positioned Div for Local Video */}
      <div
        className="absolute bottom-4 right-4 w-1/5 h-1/5 bg-gray-400 overflow-hidden rounded-lg"
        style={{ border: '2px solid #fff' }}
      >
        <div
          ref={localVideoRef}
          className="w-full h-full"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </div>
    </div>
    </center>

    {/* End Call Button */}
    <button
      className="mt-6 px-6 py-3 bg-red-600 text-white rounded-md hover:bg-red-700"
      onClick={handleEndCall}
    >
      End Call
    </button>
  </div>
  );
};

export default RepVideoInterview;
