import axiosInstance from "../../../api/axiosInstanceForMasterAPI";

// Common wrapper for API calls
const apiCallWrapper = async (apiCall, ...args) => {
    let response = null;
    let error = null;
    try {
        response = await apiCall(...args);
        return [response?.data, null]; // Return data and null error if successful
    } catch (e) {
        if (e.response) {
            // Handle server-side errors
            error = e.response.data?.msg || "Server error occurred.";
            console.log("Error Response:", e.response.data);
            console.log("Error Status:", e.response.status);
        } else if (e.request) {
            // Handle client-side errors or network issues
            error = "No response received from the server.";
            console.log("Error Request:", e.request);
        } else {
            // Handle other errors
            error = "Something went wrong.";
            console.log("Error Message:", e.message);
        }
        return [null, error]; // Return null data and error message
    }
};

// Service methods using the wrapper
const LoanApplicationsService = {
    get: async (applicationId) =>
        apiCallWrapper(() => axiosInstance.get(`/api/v1/app/applications/view?id=${applicationId}`)),

    create: async (data) =>
        apiCallWrapper(() => axiosInstance.post("/api/v1/app/applications/create", data)),

    save: async (data) =>
        apiCallWrapper(() => axiosInstance.post("/api/v1/app/applications/save-section", data)),

    uploadFile: async (data) =>
        apiCallWrapper(() => axiosInstance.post("/api/v1/external/applications/save-file", data)),

    saveFileIdAndLocation: async (data) =>
        apiCallWrapper(() => axiosInstance.post("/api/v1/external/applications/save-location", data)),
    sendVideoPDLink: async (data) =>
        apiCallWrapper(() => axiosInstance.post("/api/v1/app/applications/send-video-pd-link", data)),
    closeVisit: async (application_id) =>
      apiCallWrapper(() => axiosInstance.post("/api/v1/app/applications/close-visit", {
        application_id
      }))
};

export default LoanApplicationsService;
