import axios from "axios";
import Cookies from "js-cookie";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SCHEDULED_CALL_SERVICE_BASE_URL}/api`, // Update this with your API's base URL
  header: {
    "Content-Type": "application/json",
  },
});

// Add interceptors if needed (e.g., for token management)
axiosInstance.interceptors.request.use(
  (config) => {
    // Add token from localStorage or any authentication mechanism
    const token = Cookies.get('ac-ses-id')
    if(token){
      config.headers.Authorization = 'Bearer '+token
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
