import React, { useState, useEffect } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css"; // Import the CSS for the datetime picker
import moment from "moment";
import { useNavigate } from 'react-router-dom';

import ScheduledCallsService from "../features/scheduledCalls/services/ScheduledCallsService";
import LoanApplicationsService from "../features/loanApplications/services/LoanApplicationsService";

const LoanApplications = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("live");
  const [loans, setLoans] = useState([]);
 
  const [selectedLoanIndex, setSelectedLoanIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [fromDateTime, setFromDateTime] = useState(moment());
  const [tillDateTime, setTillDateTime] = useState(moment().add(1, "hour"));
  const [textToCopy, setTextToCopy] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async (loan) => {
    try {
      
      await navigator.clipboard.writeText(`Hi ${loan.applicant_name}, Your video PD is scheduled between ${formatScheduledCall(loan.call_scheduled_from,loan.call_scheduled_till)}. Meeting link : ${process.env.REACT_APP_URL}/lobby?application_id=${loan.application_id}`); // Copy the text
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleJoinNow = async (record) => {
    const callToken = record.call_token || (await ScheduledCallsService.generateCallToken(record.application_id, {})).rep_call_token;
    navigate(`/video-interview-rep?call_token=${callToken}&room_id=${record.application_id}`);
};
  const handleScheduleCall = async () => {
    if (selectedLoanIndex !== null) {
      const updatedLoans = [...loans];
      const selectedLoan = updatedLoans[selectedLoanIndex];

      // Update the schedule in the local state
      selectedLoan.call_scheduled_from = fromDateTime;
      selectedLoan.call_scheduled_till = tillDateTime;

      setLoans(updatedLoans);
      setShowModal(false);

      // Persist the schedule to the backend
      try {
         await ScheduledCallsService.updateSchedule(selectedLoan.application_id, {
          call_scheduled_from: fromDateTime,
          call_scheduled_till: tillDateTime,
        });
       
        alert("Call scheduled successfully!");
        fetchScheduledCalls();
      } catch (error) {
        console.error("Error scheduling call:", error);
        alert("Failed to schedule the call.");
      }
    }
  };

  const formatScheduledCall = (from, till) => {
    return (
      moment(from).format("Do MMM YY, hh:mm a") +
      " to " +
      moment(till).format("hh:mm a")
    );
  };

  const fetchScheduledCalls = async () => {
    try {
      const data = await ScheduledCallsService.getAll();
      setLoans(data);
    } catch (err) {
      console.error(err);
    }
  };

  const markAsCompleted = async (application_id) => {
    try {
      const y = window.confirm("Do you really wish to mark the call verification as complete?")
      if(y){
        await LoanApplicationsService.closeVisit(application_id)
        await ScheduledCallsService.updateStatusToCompleted(application_id);
        fetchScheduledCalls();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getStatusBasedClass = (status)=> {
    let color = '';
    switch(status){
      case 'Pending':color='red';break;
      case 'Joined':color='blue';break;
      case 'Scheduled':color='yellow';break;
      case 'Completed':color='green';break;
      default: color='black'
    }
    return `bg-${color}-500`
  }

  const shouldShow = (status)=>{
    let res = false;
    if(activeTab=="live" && status!="Completed"){
      return true;
    }
    if(activeTab=="closed" && status=="Completed"){
      return true;
    }
  }

  const renderApplications = (loanApplications) => {
    if(!loanApplications.length){
      return <></>
    }
    return loanApplications?.map((application, index) => (
        <div
            style={{display:shouldShow(application.status)?"grid":"none"}}
            key={index}
            className="grid grid-cols-9 items-center border-b border-gray-200 py-4"
        >
            <div className="flex items-center col-span-3">
                <img
                    src={'/user.png'}
                    alt="Profile"
                    className="w-10 h-10 rounded-full mr-4"
                />
                <div>
                    <p className="font-semibold text-gray-900">{application.applicant_name}</p>
                  
                </div>
            </div>

            <div className="col-span-3">
                <p className="text-gray-900">
                {application.call_scheduled_from && (
                      // If the scheduled call is in the past, format the date
                      <>

                      <span>{formatScheduledCall(application.call_scheduled_from,application.call_scheduled_till)}</span>
                      </>
                    )}
                </p>
            </div>

            <div className="col-span-1">
                <span
                    className={`px-3 py-1 rounded-full text-sm ${
                        application.status === "CAM Available"
                            ? "bg-green-100 text-green-800"
                            : "bg-blue-100 text-blue-800"
                    }`}
                >
                    {application.status}
                </span>
            </div>
            <div className="flex justify-center col-span-2">
                <a href={`/loan-application-details?application_id=${application.application_id}`} className="px-4 py-2 bg-blue-600 text-white text-sm rounded-md mr-2">
                    View Details
                </a>
            
                  



 
                
            </div>
        </div>
    ));
};

  useEffect(() => {
    fetchScheduledCalls();
  }, []);

  return (
    <>
    <div className="bg-gray-50 min-h-screen p-6">
    {/* Tabs */}
    <div className="flex justify-between items-center bg-white p-4 rounded-t-lg shadow-sm">
        <div className="flex space-x-4">
            <button
                className={`px-6 py-2 rounded-md ${
                    activeTab === "live"
                        ? "bg-gray-200 text-gray-900 font-semibold"
                        : "text-gray-500 hover:text-gray-900"
                }`}
                onClick={() => setActiveTab("live")}
            >
                Live Loan Applications
            </button>
            <button
                className={`px-6 py-2 rounded-md ${
                    activeTab === "closed"
                        ? "bg-gray-200 text-gray-900 font-semibold"
                        : "text-gray-500 hover:text-gray-900"
                }`}
                onClick={() => setActiveTab("closed")}
            >
                Closed Loan Applications
            </button>
        </div>
        <button onClick={()=>{window.location.href="/new-loan-application"}} className="px-4 py-2 bg-green-600 text-white rounded-md">
            Create Loan Application
        </button>
    </div>

    <div className="bg-white mt-4 rounded-b-lg shadow-sm">
                <div className="grid grid-cols-9 items-center border-b border-gray-200 p-4 font-semibold text-gray-600">
                    <div className="col-span-3">Applicant & Product Name</div>
                    <div  className="col-span-3">Call Schedule</div>
                    <div  className="col-span-1">Application Status</div>
                    <div className="col-span-2 text-center">Actions</div>
                </div>
                {renderApplications(loans)}
            </div>


    </div>
    {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm">
            <h3 className="text-lg font-semibold mb-4">Schedule Call</h3>
            <div className="mb-4">
              <label className="block mb-2 text-sm">From:</label>
              <Datetime
                value={fromDateTime}
                onChange={(date) => setFromDateTime(moment(date))}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-sm">Till:</label>
              <Datetime
                value={tillDateTime}
                onChange={(date) => setTillDateTime(moment(date))}
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500 mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleScheduleCall}
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
              >
                Schedule
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoanApplications;
