import React, { useState } from "react";
import AuthService from "../services/AuthService";
import Cookies from "js-cookie";

const Login = () => {
  const [step, setStep] = useState(1); // Step 1: Mobile Number, Step 2: OTP
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");

  const handleMobileSubmit = async () => {
    if (mobile.length === 10) {
      try {
        // Prepare the payload
        const payload = {
          channel: "mobile",
          address: mobile,
        };
  
        // Send POST request
        const response = await AuthService.login(payload);
        console.log({response})
  
        if (response?.status === 1) {
          setStep(2); // Move to OTP step if successful
        } else {
        //  alert("Failed to send OTP. Please try again.");
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
       // alert("An error occurred while sending OTP. Please try again.");
       setStep(2);
      }
    } else {
      alert("Please enter a valid 10-digit mobile number.");
    }
  };

  const handleOtpSubmit = async () => {
    if (otp.length === 4) {
      try {
        // Prepare the payload
        const payload = {
          channel: "mobile",
          address: mobile,
          otp
        };
  
        // Send POST request
        const response = await AuthService.verifyOtp(payload);
        console.log({response})
  
        if (response?.status === 1) {
          Cookies.set("ac-ses-id",response.data.user.access_token);
          localStorage.setItem("org_id",response.data.user.org_id)
          window.location.href="/"
        } else {
        //  alert("Failed to send OTP. Please try again.");
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
       // alert("An error occurred while sending OTP. Please try again.");
    //   window.location.href="/"
      }
    } else {
      alert("Please enter a valid 4-digit OTP.");
    }
  };

  const openMicrosftAuth = async () => {
    try {
      const res = await AuthService.getMicrosoftUrl();
      const microsoftUrl = res?.data?.authorization_url;
      if (microsoftUrl) {
        window.location.href = microsoftUrl;
      }
    } catch (err) {
      alert("Unable to initiate microsoft login");
    }
  };

  return (
    <div className="flex h-screen">
      {/* Left Section */}
      <div className="w-1/5 bg-white flex flex-col justify-center px-8 shadow-md py-6">
        {/* Logo */}
        <div>
          <img src={`/logo192.png`} alt="Logo" className="w-32 mb-8" />
        </div>

        {/* Login Form */}
        <div>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">
            Welcome Back
          </h2>
          <p className="text-sm text-gray-600 mb-6">
            Please enter your details to continue.
          </p>
          {step === 1 ? (
            <div className="w-full">
              <label
                htmlFor="mobile"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Mobile
              </label>
              <div className="flex items-center border border-gray-300 rounded-lg overflow-hidden mb-4">
                <span className="px-3 text-gray-500">+91</span>
                <input
                  type="text"
                  id="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="Enter mobile number"
                  className="flex-1 px-2 py-2 text-gray-900 focus:outline-none"
                />
              </div>
              <div className="flex flex-col items-center gap-3">
                <button
                  onClick={handleMobileSubmit}
                  className="w-full bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition"
                >
                  Send OTP
                </button>
                <p className="text-gray-500 font-light">Or Continue with</p>
                <button
                  onClick={openMicrosftAuth}
                  className="w-full flex items-center justify-center gap-3 font-medium bg-white text-black px-4 border-black border-2 py-2 rounded-lg hover:text-white hover:bg-gray-800 transition"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0H11.325V11.325H0" fill="#F24F23" />
                    <path d="M12.675 0V11.325H24V0" fill="#7EBA03" />
                    <path d="M0 12.6758H11.325V24.0008H0" fill="#3CA4EF" />
                    <path
                      d="M12.675 12.6758H24V24.0008H12.675"
                      fill="#F9BA00"
                    />
                  </svg>
                  Microsoft Account
                </button>
              </div>
            </div>
          ) : (
            <div className="w-full">
              <label
                htmlFor="otp"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                OTP
              </label>
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
              />
              <button
                onClick={handleOtpSubmit}
                className="w-full bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition"
              >
                Verify OTP
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Right Section */}
      <div
        className="w-4/5 bg-cover bg-center flex items-center justify-center text-white"
        style={{
          backgroundColor: "#B9FF4C",
        }}
      >
        <div className="text-center px-8">
          <h1
            style={{ color: "black" }}
            className="text-4xl font-bold leading-tight"
          >
            AI that writes Credit reports on its own!
          </h1>
          <p style={{ color: "black" }} className="mt-4 text-lg">
            AI that powers your lenders, supports your credit assistant, &
            generates Credit Appraisal Memos based on your policies without any
            human intervention.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
