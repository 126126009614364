import React, { useEffect } from "react";

const Notification = ({ message, type, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose(); // Automatically dismiss after 3 seconds
        }, 3000);
        return () => clearTimeout(timer); // Cleanup the timer
    }, [onClose]);

    const bgColor = type === "success" ? "bg-green-500" : "bg-red-500";
    const icon = type === "success" ? "✔️" : "❌";

    return (
        <div
            style={{position:"fixed",zIndex:1}}
            className={`fixed bottom-4 right-4 flex items-center gap-4 px-4 py-3 text-white shadow-lg rounded ${bgColor}`}
            role="alert"
        >
            <span className="text-lg"></span>
            <span>{message}</span>
            <button
                onClick={onClose}
                className="text-white ml-4 font-bold hover:text-gray-300"
                aria-label="Close"
            >
                ✖️
            </button>
        </div>
    );
};

export default Notification;