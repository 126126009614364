import React from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

const CameraUI = ({
  currentCategory,
  isLoading,
  facingMode,
  handleTakePhoto,
  closeCamera,
}) => {


  

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-4 relative w-11/12 max-w-md">
        <button
        onClick={closeCamera}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 font-bold text-lg"
        >
          ✖
        </button>
        <h2 className="text-center text-lg font-bold mb-4">{currentCategory}</h2>
        {isLoading ? (
          <div className="flex justify-center items-center">
            <img
              src="/loader.webp"
              alt="Loading..."
              className="w-16 h-16 animate-spin"
            />
            Uploading...
          </div>
        ) : (
          <Camera
            onTakePhoto={handleTakePhoto}
            idealFacingMode={facingMode}
            isFullscreen={false}
          />
        )}

      </div>
    </div>
  );
};

export default CameraUI;
