import React, { useState } from "react";
import { FaSyncAlt } from "react-icons/fa"; // Flip camera icon
import { BsCamera } from "react-icons/bs"; // Camera icon

const Controls = ({
  openCameraForCategory,
  toggleTwilioCamera,
  endCall,
}) => {
  const [showOptions, setShowOptions] = useState(false); // State to toggle dropdown
  const categories = ["Business", "Lifestyle", "Collateral"];

  const handleCategoryClick = (category) => {
    openCameraForCategory(category);
    setShowOptions(false); // Close dropdown after selection
  };

  return (
    <>
      <div className="fixed bottom-0 left-0 w-full bg-white bg-opacity-90 p-2 shadow-lg flex items-center justify-between z-10">
        {/* Click Photo Button with Dropdown */}
        <div className="relative">
          <button
            onClick={() => setShowOptions((prev) => !prev)}
            className="bg-blue-500 text-white p-3 rounded-full shadow hover:bg-blue-600 transition flex items-center"
            aria-label="Click Photo"
          >
            <BsCamera />
          </button>

          {/* Dropdown Menu */}
          {showOptions && (
            <div
              className="absolute top-0 left-full bg-white shadow rounded-lg border z-50"
              style={{
                minWidth: "150px",
                transform: "translate(-20%, -100%)", // Align near button
              }}
            >
              {categories.map((label, idx) => (
                <button
                  key={idx}
                  onClick={() => handleCategoryClick(label)}
                  className="block w-full px-4 py-2 text-left hover:bg-gray-200 transition"
                >
                  {label}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* End Call Button */}
        <button
          onClick={endCall}
          className="bg-red-500 text-white px-6 py-3 rounded-full shadow hover:bg-red-600 transition"
        >
          End Call
        </button>

        {/* Flip Camera Icon Button */}
        <button
          onClick={toggleTwilioCamera}
          className="bg-yellow-500 text-white p-3 rounded-full shadow hover:bg-yellow-600 transition flex items-center"
          aria-label="Flip Camera"
        >
          <FaSyncAlt />
        </button>
      </div>
    </>
  );
};

export default Controls;
