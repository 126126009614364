import React, { useState } from "react";

const PhotoGallery = ({ photos }) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handleTagClick = (tag, photo) => {
    console.log(`Tag ${tag} clicked for photo`, photo);
    // Add custom logic for the tag here.
  };

  return (
    <div className="p-4">
      {/* Photo Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {photos.map((photo, index) => (
          <div key={index} className="relative group">
            {/* Thumbnail */}
            <img
              src={photo.src}
              alt={photo.tag}
              className="h-auto object-cover rounded-lg cursor-pointer"
              onClick={() => setSelectedPhoto(photo)}
            />
            <center><div>{photo.tag.split('_') // Split by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
    .join(' ')}</div></center>
            {/* Tags */}
            <div style={{display:"none"}} className=" top-2 right-2 flex gap-1">
              {["Business", "Lifestyle", "Collateral"].map((tag) => (
                <button
                style={{float:'left',clear:'both'}}
                  key={tag}
                  onClick={() => handleTagClick(tag, photo)}
                  className="bg-gray-800 text-white text-xs px-2 py-1 rounded-full hover:bg-gray-600"
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Photo Popup */}
      {selectedPhoto && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50">
          <div className="relative">
            {/* Close Button */}
            <button
              onClick={() => setSelectedPhoto(null)}
              className="absolute top-2 right-2 text-white text-2xl font-bold hover:text-gray-300"
            >
              ✖
            </button>
            {/* Full-Size Photo */}
            <img
              src={selectedPhoto.src}
              alt={selectedPhoto.alt}
              className="max-w-full max-h-screen rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;