import React, { useEffect } from "react";
import LoadingView from "../../components/LoadingView/LoadingView";
import AuthService from "../../features/auth/services/AuthService";
import { MICROSOFT_CLIENT_STATE } from "../../config/constants";
import Cookies from "js-cookie";

const MicrosoftCallback = () => {
  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    try {
      const url = new URL(window.location.href);

      // Get the search parameters (query string)
      const urlParams = new URLSearchParams(url.search);

      const params = {
        code: urlParams.get("code"),
        state: urlParams.get("state"),
        session_state: urlParams.get("session_state"),
        client_state: MICROSOFT_CLIENT_STATE,
      };

      const response = await AuthService.microsoftAuthCallback(params);
      if (response?.status === 1) {
        Cookies.set("ac-ses-id", response.data.user.access_token);
        localStorage.setItem("org_id", response.data.user.org_id);
        window.location.replace('/') 
      } else {
        window.location.replace('/login') 
      }
    } catch (err) {
      window.location.replace('/login') 
    }
  };
  return <LoadingView />;
};

export default MicrosoftCallback;
