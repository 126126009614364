import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./IncomeEligibility.css";
import { data } from "./IncomeEligibilityData";
import { v4 as uuidv4 } from "uuid";
import { produce } from "immer";
import LoanApplicationsService from "../../features/loanApplications/services/LoanApplicationsService";
import { useLocation } from "react-router-dom";

const IncomeEligibility = forwardRef((props, ref) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { formData } = props;
  const incomeSourceField = formData.fields.filter(
    (item) => item.name === "income_sources"
  )[0];

  const incomeDetailsField = formData.fields.find(
    (item) => item.name === "income_details"
  );
  const dscrField = incomeDetailsField.properties.find(
    (item) => item.name === "dscr"
  );

  const foirField = incomeDetailsField.properties.find(
    (item) => item.name === "foir"
  );

  const incomeSourceOptions = incomeSourceField.options;

  const [incomes, setIncomes] = useState([]);
  const [totalNetIncome, setTotalNetIncome] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [familyExpenses, setFamilyExpenses] = useState(0);
  const [obligations, setObligations] = useState(0);
  const [editable, setEditable] = useState(false);
  const [dscr, setDscr] = useState(0);
  const [foir, setFoir] = useState(0);
  const [incomeSources, setIncomeSources] = useState([
    { id: "cbO", name: "lalit", sources: [] },
    { id: "cb1", name: "john", sources: [] },
  ]);

  useImperativeHandle(ref, () => ({
    getIncomeSources: () => {
      return {
        income_details: {
          total_net_income: totalNetIncome,
          dscr: dscr,
          foir: foir,
        },
        family_expenses: familyExpenses,
        obligations,
        income_sources: incomeSources,
      };
    }, // Exposing the state to the parent
  }));

  const handleIncomeChange = (e, incomeSourceIndex) => {
    const val = e.target.value;
    if (val) {
      const incomeSource = incomeSourceOptions.filter(
        (item) => item.key === val
      )[0];
      const formattedIncomeSource = {
        name: incomeSource.key,
        amount: 0,
        gross_margin: 0,
        gross_margin_amount: 0,
        expenditure_margin: 0,
        expenditure_margin_amount: 0,
        net_income: 0,
        metaData: incomeSource,
        basis_text: incomeSource.key,
      };
      const grossMarginRange = getGrossMarginRange(
        formattedIncomeSource
      ).default;
      const expenditureMarginRange = getExpenditureMarginRange(
        formattedIncomeSource
      ).default;

      formattedIncomeSource.gross_margin = grossMarginRange;
      formattedIncomeSource.expenditure_margin = expenditureMarginRange;

      const newState = produce(incomeSources, (draft) => {
        draft[incomeSourceIndex].sources.push(formattedIncomeSource);
      });
      setIncomeSources(newState);
    }
  };

  const deleteIncome = (incomeIndex, incomeSourceIndex) => {
    const newState = produce(incomeSources, (draft) => {
      draft[incomeSourceIndex].sources.splice(incomeIndex, 1);
    });
    setIncomeSources(newState);
  };

  const handleIncomeTurnOver = (e, i, incomeSourceIndex) => {
    const newState = produce(incomeSources, (draft) => {
      const income = draft[incomeSourceIndex].sources[i];
      const amount = e.target.value;

      const grossMargin = income.gross_margin;
      const expenditureMargin = income.expenditure_margin;

      income.amount = amount;
      income.gross_margin_amount = getValueFromPercentage(grossMargin, amount);
      income.expenditure_margin_amount = getValueFromPercentage(
        expenditureMargin,
        income.gross_margin_amount
      );
      income.net_income = calculateNetIncome(
        income.expenditure_margin_amount,
        income.gross_margin_amount
      );
    });
    setIncomeSources(newState);
  };

  const selectIncomeBasis = (basis, i, incomeSourceIndex) => {
    // const tempIncomes = [...incomes];
    // const income = { ...tempIncomes[i] };
    // const tempBasis = income?.basis ? [...income.basis] : [];
    // tempBasis.push({ ...basis, value: 1 });
    // income.basis = tempBasis;
    // income.amount += basis.amount;
    // tempIncomes[i] = income;
    // setIncomes(tempIncomes);

    const newState = produce(incomeSources, (draft) => {
      const income = draft[incomeSourceIndex].sources[i];

      const grossMargin = income.gross_margin;
      const expenditureMargin = income.expenditure_margin;

      if (Array.isArray(income.basis)) {
        income.basis.push({ ...basis, value: 1 });
      } else {
        income.basis = [{ ...basis, value: 1 }];
      }
      income.amount += basis.amount;
      income.gross_margin_amount = getValueFromPercentage(
        grossMargin,
        income.amount
      );
      income.expenditure_margin_amount = getValueFromPercentage(
        expenditureMargin,
        income.gross_margin_amount
      );
      income.net_income = calculateNetIncome(
        income.expenditure_margin_amount,
        income.gross_margin_amount
      );
      income.basis_text = getBasisText(income.basis);
    });
    setIncomeSources(newState);
  };

  const unSelectIncomeBasis = (incomeIndex, basisKey, incomeSourceIndex) => {
    // const tempIncomes = [...incomes];
    // const income = { ...tempIncomes[incomeIndex] };
    // const tempBasis = [...income.basis];
    // const basisIndex = tempBasis.findIndex((item) => item.key === basisKey);
    // const basis = tempBasis[basisIndex];
    // tempBasis.splice(basisIndex, 1);
    // income.basis = tempBasis;
    // income.amount -= basis.amount;
    // tempIncomes[incomeIndex] = income;
    // setIncomes(tempIncomes);

    const newState = produce(incomeSources, (draft) => {
      const income = draft[incomeSourceIndex].sources[incomeIndex];

      const grossMargin = income.gross_margin;
      const expenditureMargin = income.expenditure_margin;

      const allBasis = income.basis;
      const basisIndex = allBasis.findIndex((item) => item.key === basisKey);
      const basis = allBasis[basisIndex];
      income.amount -= basis.amount;
      income.gross_margin_amount = getValueFromPercentage(
        grossMargin,
        income.amount
      );
      income.expenditure_margin_amount = getValueFromPercentage(
        expenditureMargin,
        income.gross_margin_amount
      );
      income.net_income = calculateNetIncome(
        income.expenditure_margin_amount,
        income.gross_margin_amount
      );
      allBasis.splice(basisIndex, 1);
      income.basis_text = getBasisText(allBasis);
    });
    setIncomeSources(newState);
  };

  const incrementIncomeBasisValue = (
    incomeIndex,
    basisIndex,
    incomeSourceIndex
  ) => {
    // const tempIncomes = [...incomes];
    // const income = { ...tempIncomes[incomeIndex] };
    // const tempBasis = [...income.basis];
    // const basis = tempBasis[basisIndex];
    // tempBasis[basisIndex].value += 1;
    // income.basis = tempBasis;
    // income.amount += basis.amount;
    // tempIncomes[incomeIndex] = income;
    // setIncomes(tempIncomes);

    const newState = produce(incomeSources, (draft) => {
      const income = draft[incomeSourceIndex].sources[incomeIndex];

      const grossMargin = income.gross_margin;
      const expenditureMargin = income.expenditure_margin;

      const basis = income.basis[basisIndex];
      basis.value += 1;
      income.amount += basis.amount;
      income.gross_margin_amount = getValueFromPercentage(
        grossMargin,
        income.amount
      );
      income.expenditure_margin_amount = getValueFromPercentage(
        expenditureMargin,
        income.gross_margin_amount
      );
      income.net_income = calculateNetIncome(
        income.expenditure_margin_amount,
        income.gross_margin_amount
      );
      income.basis_text = getBasisText(income.basis);
    });
    setIncomeSources(newState);
  };

  const decrementIncomeBasisValue = (
    incomeIndex,
    basisIndex,
    incomeSourceIndex
  ) => {
    // const tempIncomes = [...incomes];
    // const income = { ...tempIncomes[incomeIndex] };
    // const tempBasis = [...income.basis];
    // const basis = tempBasis[basisIndex];

    // if (tempBasis[basisIndex].value > 1) {
    //   tempBasis[basisIndex].value -= 1;
    //   income.basis = tempBasis;
    //   income.amount -= basis.amount;
    //   tempIncomes[incomeIndex] = income;
    //   setIncomes(tempIncomes);
    // } else {
    //   unSelectIncomeBasis(incomeIndex, tempBasis[basisIndex].key);
    // }

    let shouldUpdate = false;

    const newState = produce(incomeSources, (draft) => {
      const income = draft[incomeSourceIndex].sources[incomeIndex];

      const grossMargin = income.gross_margin;
      const expenditureMargin = income.expenditure_margin;

      const basis = income.basis[basisIndex];
      if (basis.value > 1) {
        basis.value -= 1;
        income.amount -= basis.amount;
        income.gross_margin_amount = getValueFromPercentage(
          grossMargin,
          income.amount
        );
        income.expenditure_margin_amount = getValueFromPercentage(
          expenditureMargin,
          income.gross_margin_amount
        );
        income.net_income = calculateNetIncome(
          income.expenditure_margin_amount,
          income.gross_margin_amount
        );
        income.basis_text = getBasisText(income.basis);
        shouldUpdate = true;
      } else {
        unSelectIncomeBasis(incomeIndex, basis.key, incomeSourceIndex);
      }
    });
    if (shouldUpdate) {
      setIncomeSources(newState);
    }
  };

  const getBasisText = (basisList) => {
    return basisList.map((item) => `${item.key}(${item.value})`).join(",");
  };

  const getGrossMarginRange = (income) => {
    const amount = income.amount;
    const grossMargins = income.metaData.margins;
    const grossMargin = grossMargins.find(
      (item) => amount >= item.amount_min && amount <= item.amount_max
    );
    return {
      min: grossMargin.margin_min,
      max: grossMargin.margin_max,
      default: grossMargin.default_margin,
    };
  };

  const getExpenditureMarginRange = (income) => {
    const amount = income.amount;
    const margins = income.metaData.expenditure_margins;
    const margin = margins.find(
      (item) =>
        amount >= item.min_gross_amount && amount <= item.max_gross_amount
    );
    return {
      min: margin.margin_min,
      max: margin.margin_max,
      default: margin.default_margin,
    };
  };

  const handleEditClick = () => {
    // const newState = produce(incomeSources, (draft) => {
    //   draft.map((incomeSource) => {
    //     const incomes = incomeSource.sources;
    //     incomes.forEach((income) => {
    //       const gross_margin_amount = getValueFromPercentage(
    //         getGrossMarginRange(income).default,
    //         income.amount
    //       );
    //       const expenditure_margin_amount = getValueFromPercentage(
    //         getExpenditureMarginRange(income).default,
    //         gross_margin_amount
    //       );
    //       income.gross_margin = getGrossMarginRange(income).default;
    //       income.gross_margin_amount = gross_margin_amount;
    //       income.expenditure_margin_amount = expenditure_margin_amount;
    //       income.expenditure_margin = getExpenditureMarginRange(income).default;
    //       income.net_income = calculateNetIncome(
    //         expenditure_margin_amount,
    //         gross_margin_amount
    //       );
    //     });
    //   });
    // });
    // setIncomeSources(newState);
    setIsEdit(true);
  };

  const getValueFromPercentage = (percent, val) => {
    return (percent / 100) * val;
  };

  const handleIsEditable = () => {
    let incomes = [];
    let temp = true;

    for (let i = 0; i < incomeSources.length; i++) {
      if (!incomeSources[i].sources.length) {
        temp = false;
        break;
      } else {
        incomes = [...incomes, ...incomeSources[i].sources];
      }
    }

    if (!incomes?.length) {
      temp = false;
      return;
    }

    for (let i = 0; i < incomes.length; i++) {
      const item = incomes[i];
      if (item.amount <= 0) {
        temp = false;
        break;
      }
    }

    setEditable(temp);
  };

  const handleGrossMarginChange = (e, incomeIndex, incomeSourceIndex) => {
    // const tempIncomes = [...incomes];
    // const tempIncome = tempIncomes[incomeIndex];
    // const gross_margin_amount = getValueFromPercentage(
    //   e.target.value,
    //   tempIncome.amount
    // );
    // const expenditure_margin_amount = getValueFromPercentage(
    //   getExpenditureMarginRange(tempIncome).default,
    //   gross_margin_amount
    // );
    // tempIncome.gross_margin = parseFloat(e.target.value);
    // tempIncome.gross_margin_amount = gross_margin_amount;
    // tempIncome.expenditure_margin_amount = expenditure_margin_amount;
    // tempIncome.net_income = calculateNetIncome(
    //   expenditure_margin_amount,
    //   gross_margin_amount
    // );
    // tempIncomes[incomeIndex] = tempIncome;
    // setIncomes(tempIncomes);
    const newState = produce(incomeSources, (draft) => {
      const tempIncomes = draft[incomeSourceIndex].sources;
      const tempIncome = tempIncomes[incomeIndex];
      const gross_margin_amount = getValueFromPercentage(
        e.target.value,
        tempIncome.amount
      );
      const expenditure_margin_amount = getValueFromPercentage(
        getExpenditureMarginRange(tempIncome).default,
        gross_margin_amount
      );
      tempIncome.gross_margin = parseFloat(e.target.value);
      tempIncome.gross_margin_amount = gross_margin_amount;
      tempIncome.expenditure_margin_amount = expenditure_margin_amount;
      tempIncome.net_income = calculateNetIncome(
        expenditure_margin_amount,
        gross_margin_amount
      );
    });
    setIncomeSources(newState);
  };

  const handleExpenditureMarginChange = (e, incomeIndex, incomeSourceIndex) => {
    // const tempIncomes = [...incomes];
    // const tempIncome = tempIncomes[incomeIndex];
    // const expenditure_margin_amount = getValueFromPercentage(
    //   e.target.value,
    //   tempIncome.gross_margin_amount
    // );
    // tempIncome.expenditure_margin = parseFloat(e.target.value);
    // tempIncome.expenditure_margin_amount = expenditure_margin_amount;
    // tempIncome.net_income = calculateNetIncome(
    //   expenditure_margin_amount,
    //   tempIncome.gross_margin_amount
    // );
    // tempIncomes[incomeIndex] = tempIncome;
    // setIncomes(tempIncomes);
    const newState = produce(incomeSources, (draft) => {
      const tempIncomes = draft[incomeSourceIndex].sources;
      const tempIncome = tempIncomes[incomeIndex];
      const expenditure_margin_amount = getValueFromPercentage(
        e.target.value,
        tempIncome.gross_margin_amount
      );
      tempIncome.expenditure_margin = parseFloat(e.target.value);
      tempIncome.expenditure_margin_amount = expenditure_margin_amount;
      tempIncome.net_income = calculateNetIncome(
        expenditure_margin_amount,
        tempIncome.gross_margin_amount
      );
    });
    setIncomeSources(newState);
  };

  const isValidNumberInput = (event) => {
    const regex = /^[0-9]*$/;

    if (regex.test(event.target.value) || event.target.value === "") {
      return true;
    }
    return false;
  };

  const calculateNetIncome = (expenditure, grossMargin) => {
    return Math.round(grossMargin - expenditure);
  };

  const calculateTotalNetIncome = () => {
    let incomes = [];

    incomeSources.forEach((incomeSource) => {
      incomes = [...incomes, ...(incomeSource.sources || [])];
    });

    const totalNetIncome = incomes.reduce(
      (accumulator, current) => accumulator + current.net_income,
      0
    );
    setTotalNetIncome(Math.round(totalNetIncome));
  };

  const calculateDscr = () => {
    const { config } = dscrField;
    const a = totalNetIncome - familyExpenses;
    const c = config.emi_per_lac;
    const d = obligations;
    const f = config.target_dscr;
    const g = a / f - d;
    const h = (g / c) * 100000;
    setDscr(Math.round(h));
  };

  const calculateFoir = () => {
    const { config } = foirField;
    const c = totalNetIncome - familyExpenses;
    const d = obligations;
    const e = c - d;
    const b = config.emi_per_lac;
    const f = (55 / 100) * e;
    const g = (f / b) * 100000;
    setFoir(Math.round(g));
  };

  const fetchApplication = async () => {
    const applicationId = queryParams.get("application_id");

    try {
      const [res, err] = await LoanApplicationsService.get(applicationId);
      const sections = res?.data?.application?.sections || [];
      const coBorrowserSection = sections.find(
        (item) => item?.name === "co_borrower_details"
      );
      const borrowerSection = sections.find(
        (item) => item?.name === "borrower_details"
      );

      const borrower = {
        id: uuidv4(),
        name: borrowerSection?.data?.name?.value,
        sources: [],
      };

      const coBorrowers = (
        coBorrowserSection?.data?.co_borrower?.value || []
      ).map((item) => ({
        id: uuidv4(),
        name: item?.value?.name?.value,
        sources: [],
      }));

      setIncomeSources([borrower, ...coBorrowers]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchApplication();
  }, []);

  useEffect(() => {
    handleIsEditable();
    calculateTotalNetIncome();
    calculateDscr();
    calculateFoir();
  }, [incomeSources]);

  useEffect(() => {
    calculateDscr();
    calculateFoir();
  }, [obligations, totalNetIncome, familyExpenses]);

  return (
    <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg">
      <div className="z-10 sticky top-[-24px] bg-white px-6 pt-6 border-b border-[#ddd]">
        <div className="flex items-center mb-2 p-2 bg-gray-100 rounded-md">
          <label className=" text-gray-500 text-sm font-medium mr-4">
            Total Net Income:
          </label>
          <p className="font-semibold">{totalNetIncome}</p>
        </div>
        <div>
          <p className="text-gray-500 font-semibold mb-2 text-sm">
            Eligibility based on
          </p>
          <div className="flex gap-1">
            <div className="flex items-center mb-4 p-2 bg-gray-100 rounded-md w-full">
              <label className=" text-gray-500 font-medium mr-2 text-sm">
                DSCR:
              </label>
              <p className="font-semibold">{dscr}</p>
            </div>
            <div className="flex items-center mb-4 p-2 bg-gray-100 rounded-md w-full">
              <label className=" text-gray-500 font-medium mr-2 text-sm">
                FOIR:
              </label>
              <p className="font-semibold">{foir}</p>
            </div>
          </div>
        </div>
        <div className="flex gap-1">
          <div className="flex w-full  items-center mb-4">
            <label className="w-1/4 text-gray-700 font-medium mr-2 text-sm">
              Family Expenses:
            </label>
            <input
              type="text"
              disabled={isEdit}
              value={familyExpenses}
              onChange={(e) => {
                if (isValidNumberInput(e)) {
                  setFamilyExpenses(e.target.value);
                }
              }}
              className="w-3/4 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex w-full items-center mb-4">
            <label className="w-1/4 text-gray-700 font-medium mr-2 text-sm">
              Obligations
            </label>
            <input
              disabled={isEdit}
              type="text"
              className="w-3/4 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={obligations}
              onChange={(e) => {
                if (isValidNumberInput(e)) {
                  setObligations(e.target.value);
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="p-6">
        {!isEdit && (
          <div className="mb-6 flex flex-col gap-2">
            <p className="w-full text-lime-500 font-semibold ">
              Select income source to proceed{" "}
            </p>
            {incomeSources.map((incomeSource, incomeSourceIndex) => (
              <div key={incomeSource.id}>
                <div className="p-2 bg-gray-100 rounded-lg flex flex-col gap-2">
                  <p className="font-semibold capitalize">
                    {incomeSource.name}
                  </p>
                  <p className="text-sm capitalize">Select Income Sources</p>
                  <div className="flex mb-1">
                    <select
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      onChange={(e) => handleIncomeChange(e, incomeSourceIndex)}
                      readOnly
                    >
                      <option value="">Income Sources</option>
                      {incomeSourceOptions.map((item) => {
                        const isSelected = (incomeSource.sources || []).some(
                          (selectedIncome) => selectedIncome.name === item.key
                        );
                        return (
                          <option
                            disabled={isSelected}
                            key={item.key}
                            value={item.key}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {incomeSource.sources.map((item, i) => (
                    <div
                      key={item.key}
                      className="bg-white rounded-lg p-2 relative"
                    >
                      <div
                        onClick={() => deleteIncome(i, incomeSourceIndex)}
                        className="absolute h-5 w-5 flex rounded-full justify-center items-center bg-black cursor-pointer"
                        style={{ top: -5, right: -5 }}
                      >
                        <p className=" text-white text-sm leading-none">x</p>
                      </div>
                      <div className="flex gap-1 mb-2">
                        <div className="w-full">
                          <label className=" text-gray-700 font-medium mr-4 text-sm">
                            Income Source
                          </label>
                          <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mt-1"
                            value={item?.metaData?.label}
                            onChange={() => {}}
                          />
                        </div>
                        {Array.isArray(item?.metaData?.basis) ? (
                          <div className="w-full" />
                        ) : (
                          <div className="w-full">
                            <label className=" text-gray-700 font-medium mr-4 text-sm">
                              Monthly Turnover
                            </label>
                            <input
                              type="text"
                              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mt-1"
                              onChange={(e) => {
                                if (isValidNumberInput(e)) {
                                  handleIncomeTurnOver(e, i, incomeSourceIndex);
                                }
                              }}
                              value={item.amount}
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        {item?.metaData?.sub_label &&
                          Array.isArray(item?.metaData?.basis) && (
                            <div>
                              <label className="w-1/4 text-gray-700 font-medium mr-4 text-sm ">
                                {item.metaData.sub_label}
                              </label>
                              <div className="mt-1">
                                {item.metaData.basis.map(
                                  (basis, basisIndex) => {
                                    const isSelected = (item?.basis || []).some(
                                      (selectedBasis) =>
                                        selectedBasis.key === basis.key
                                    );
                                    return (
                                      <div
                                        key={basis.key}
                                        className={`py-1 px-2 m-1 cursor-pointer rounded-full border-2 inline-block ${
                                          isSelected
                                            ? "bg-green-500 border-green-500"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          isSelected
                                            ? unSelectIncomeBasis(
                                                i,
                                                basis.key,
                                                incomeSourceIndex
                                              )
                                            : selectIncomeBasis(
                                                basis,
                                                i,
                                                incomeSourceIndex
                                              )
                                        }
                                      >
                                        <p
                                          className={`text-xs text-gray-500 ${
                                            isSelected ? "text-white" : ""
                                          }`}
                                        >
                                          {basis.key}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="mt-3 grid grid-cols-2 gap-3">
                        {Array.isArray(item?.basis) &&
                          (item?.basis).map((basis, basisIndex) => (
                            <div className="flex justify-between">
                              <div>
                                <p className=" text-black font-medium mr-4 text-sm ">
                                  {basis.key}
                                </p>
                                <p className="text-xs">{basis.sub_key}</p>
                              </div>
                              <div className="flex border-2 border-gray-200 rounded-md">
                                <div
                                  onClick={() =>
                                    decrementIncomeBasisValue(
                                      i,
                                      basisIndex,
                                      incomeSourceIndex
                                    )
                                  }
                                  className="cursor-pointer w-8 flex items-center justify-center flex-col h-full text-gray-400 text-lg"
                                >
                                  -
                                </div>
                                <div className=" w-8 flex items-center justify-center flex-col h-full">
                                  {basis.value}
                                </div>
                                <div
                                  onClick={() =>
                                    incrementIncomeBasisValue(
                                      i,
                                      basisIndex,
                                      incomeSourceIndex
                                    )
                                  }
                                  className="cursor-pointer w-8 flex items-center justify-center flex-col h-full text-gray-400 text-lg"
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
        <button
          onClick={(e) => {
            e.preventDefault();
            isEdit ? setIsEdit(false) : handleEditClick();
          }}
          disabled={!editable}
          className="mb-6 disabled:bg-gray-400 w-full px-6 py-3 bg-black text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {isEdit ? "Go Back" : "Edit Eligibility"}
        </button>
        {isEdit && (
          <div className="flex flex-col gap-2">
            {incomeSources.map((incomeSource, incomeSourceIndex) => {
              return (
                <div
                  className="p-2 bg-gray-100 rounded-lg"
                  key={incomeSource.id}
                >
                  <p className="w-full text-black font-semibold capitalize">
                    {incomeSource.name}
                  </p>
                  {incomeSource.sources.map((income, incomeIndex) => {
                    const grossMarginTooltipGutter = parseInt(
                      ((income.gross_margin - getGrossMarginRange(income).min) /
                        (getGrossMarginRange(income).max -
                          getGrossMarginRange(income).min)) *
                        100
                    );
                    const expenditureMarginTooltipGutter = parseInt(
                      ((income.expenditure_margin -
                        getExpenditureMarginRange(income).min) /
                        (getExpenditureMarginRange(income).max -
                          getExpenditureMarginRange(income).min)) *
                        100
                    );

                    return (
                      <div className="my-3">
                        <p className="w-full text-green-500 font-medium text-sm">
                          {income.metaData.label}
                        </p>
                        <div className="w-full flex justify-between items-center mb-2">
                          <div className="w-full ">
                            <p className="text-slate-500 font-medium text-sm ">
                              Monthly Turnover: {income.amount}
                            </p>
                          </div>
                          <div className="w-full">
                            <p className="text-right text-slate-500 font-medium text-sm ">
                              Net Income: {income.net_income}
                            </p>
                          </div>
                        </div>
                        <div className="bg-white rounded-lg p-4">
                          <div>
                            <p className="w-full text-slate-500 font-medium text-sm mb-6">
                              Gross Margin
                            </p>
                            <div className="flex justify-between  gap-3 items-center">
                              <div>
                                <p className="w-full text-black font-semibold  ">
                                  {getGrossMarginRange(income).min}{" "}
                                </p>
                              </div>
                              <div className="h-8 w-full">
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="range"
                                    className="range-input w-full bg-gray-300 appearance-none cursor-pointer rounded-lg "
                                    min={getGrossMarginRange(income).min}
                                    max={getGrossMarginRange(income).max}
                                    step={0.25}
                                    value={income.gross_margin}
                                    onChange={(e) =>
                                      handleGrossMarginChange(
                                        e,
                                        incomeIndex,
                                        incomeSourceIndex
                                      )
                                    }
                                  />
                                  <span
                                    className={`bg-black absolute top-[-20px]  rounded-full p-1 px-2`}
                                    style={{
                                      left: `max(0%, min(100%, ${grossMarginTooltipGutter}%))`,
                                      transform: "translateX(-50%)",
                                    }}
                                  >
                                    <p className="text-xs text-white">
                                      {income?.gross_margin.toFixed(2)}%
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p className="w-full text-black font-semibold ">
                                  {getGrossMarginRange(income).max}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-6">
                            <p className="w-full text-slate-500 font-medium text-sm mb-6">
                              Operation Expenditure (%)
                            </p>
                            <div className="flex justify-between  gap-3 items-center">
                              <div>
                                <p className="w-full text-black font-semibold  ">
                                  {getExpenditureMarginRange(income).min}
                                </p>
                              </div>
                              <div className="h-8 w-full">
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="range"
                                    className="range-input w-full bg-gray-300 appearance-none cursor-pointer rounded-lg "
                                    min={getExpenditureMarginRange(income).min}
                                    max={getExpenditureMarginRange(income).max}
                                    step={0.25}
                                    value={income.expenditure_margin}
                                    onChange={(e) =>
                                      handleExpenditureMarginChange(
                                        e,
                                        incomeIndex,
                                        incomeSourceIndex
                                      )
                                    }
                                  />
                                  <span
                                    className={`bg-black absolute top-[-20px]  rounded-full p-1 px-2`}
                                    style={{
                                      left: `max(0%, min(100%, ${expenditureMarginTooltipGutter}%))`,
                                      transform: "translateX(-50%)",
                                    }}
                                  >
                                    <p className="text-xs text-white">
                                      {income?.expenditure_margin.toFixed(2)}%
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p className="w-full text-black font-semibold ">
                                  {getExpenditureMarginRange(income).max}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
});

export default IncomeEligibility;
