import axios from "axios";
import Cookies from "js-cookie";
 

// Create an Axios instance
const axiosInstanceForMasterAPI = axios.create({
  baseURL: `${process.env.REACT_APP_MASTER_API_BASE_URL}`, // Update this with your API's base URL
  header: {
    "Content-Type": "application/json",
    "origin":"http://localhost:3000"
  },
  withCredentials: true,
});

// Add interceptors if needed (e.g., for token management)
axiosInstanceForMasterAPI.interceptors.request.use(
  (config) => {
    // Add token from localStorage or any authentication mechanism
    const orgId = localStorage.getItem('org_id')// localStorage.getItem("authToken");
    const token = Cookies.get('ac-ses-id')
    if (orgId) {
      config.headers["x-tenant-id"] = orgId
    }
    if(token){
      config.headers.Authorization = 'Bearer '+token
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstanceForMasterAPI;
