import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom"; // For handling dynamic route params
import CameraUI from "./CameraUI";
import Controls from "./Controls";
import VideoDisplay from "./VideoDisplay";
import Video from "../Video";
import useVideoInterview from "./useVideoInterview";
import ScheduledCallsService from "../../features/scheduledCalls/services/ScheduledCallsService";
import LoanApplicationsService from "../../features/loanApplications/services/LoanApplicationsService";

const VideoInterview = () => {
   const [coordinates, setCoordinates] = useState({
      latitude: null,
      longitude: null,
      error: null,
    });
  
    const [orgId, setOrgId] = useState(null);
    const [applicationId, setApplicationId] = useState(null);
    const [globalId, setGlobalId] = useState(null);
  const [callToken, setCallToken] = useState(null);
  const [roomName, setRoomName] = useState(null);
  const [fileUploadToken,setFileUploadToken] =useState(null);
  const [isCameraOpen,setIsCameraOpen] = useState(false)
  const [facingMode,setFacingMode] = useState('user')
  const [currentCategory,setCurrentCategory] = useState('Business')
  const [isLoading,setIsLoading] = useState(false)
  const location = useLocation();
  const [endCallTrigger, setEndCallTrigger] = useState(false);


  const setLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude, error: null });
        },
        (error) => {
          setCoordinates((prevState) => ({
            ...prevState,
            error: error.message,
          }));
        }
      );
    } else {
      setCoordinates((prevState) => ({
        ...prevState,
        error: "Geolocation is not supported by this browser.",
      }));
    }
  };

  const handleTakePhoto = async (dataUri) => {
    if (!coordinates?.latitude) {
      alert("Please give location access!");
      return;
    }
    setIsLoading(true);

    try {
      const blob = await fetch(dataUri).then((res) => res.blob());
      const formData = new FormData();
      formData.append("file", blob, "file.jpg");
      formData.append("file_type", currentCategory);
      formData.append("org_id", orgId);
      formData.append("global_id", globalId);
      formData.append("token", fileUploadToken);

      const [response,err] = await LoanApplicationsService.uploadFile(formData);
      if (!err && response?.data?.application_file?._id) {
        await LoanApplicationsService.saveFileIdAndLocation({
          application_id: applicationId,
          entity_id: response.data.application_file._id,
          entity_type: "file",
          tags: [`${currentCategory.toLowerCase()}_photos`],
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          token: fileUploadToken,
        });
        await ScheduledCallsService.uploadImage(applicationId,{
          presigned_url:response.data.presigned_url,
          entity_id: response.data.application_file._id,
          tag:`${currentCategory.toLowerCase()}_photos`
        })
      }
    } catch (error) {
      console.error("Error uploading photo:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const openCameraForCategory = (category)=> {
    setCurrentCategory(category)
    setIsCameraOpen(true)
  }
  
  // Fetch callToken from ScheduledCallsService
  useEffect(() => {
    setLocation();
    const queryParams = new URLSearchParams(location.search);
    const roomId = queryParams.get("room_id");

  
    const fetchCallToken = async () => {
      try {
        const response = await ScheduledCallsService.get(roomId);

        if (response && response.applicant_call_token) {
          setCallToken(response.applicant_call_token);
          setFileUploadToken(response.file_upload_token);
          setRoomName(response.application_id);  
          setApplicationId(response.application_id);  
          setOrgId(response.org_id);  
          setGlobalId(response.global_id);  
        } else {
          console.error("No call token found");
          window.location.href=`/lobby?application_id=${response.application_id}`
        }
      } catch (error) {
        console.error("Error fetching call token:", error);
      }
    };

    if (roomId) {
      setInterval(function(){
        fetchCallToken();
      },5000) 
    }
  }, []); // Adding `location` to dependency array

  // Always call useVideoInterview, but pass null until we have valid data
 

  // Show a loading screen while fetching the callToken and roomName
  if (!callToken || !roomName) {
    return (
      <div className="relative w-full h-screen bg-black">
        <div className="flex justify-center items-center h-full text-white">
          <span>Loading...</span>
        </div>
      </div>
    );
  }

  const handleEndCall = ()=> {
     ScheduledCallsService.updateStatusToCallEnded(applicationId);
     setTimeout(function(){
      setEndCallTrigger(true)
     },1000)

  }

 

  return (
    <div className="relative w-full h-screen bg-black">
     
      {callToken && roomName && <Video facingMode={facingMode} endCallTrigger={endCallTrigger} roomName={roomName} mode={'portrait'} token={callToken} endCall={()=>{}} />}
      {/* Video Display */}
    

      {/* Controls */}
      {<Controls
        isCameraOpen={isCameraOpen}
        currentCategory={currentCategory}
        openCameraForCategory={openCameraForCategory}
        toggleTwilioCamera={()=>(facingMode=='user')?setFacingMode('environment'):setFacingMode('user')}
        endCall={handleEndCall}
      />}

  
      {isCameraOpen && (
        <CameraUI
          currentCategory={currentCategory}
          isLoading={isLoading}
          facingMode={facingMode}
          fileUploadToken={fileUploadToken}
          handleTakePhoto={handleTakePhoto}
          closeCamera={()=>{setIsCameraOpen(false)}}
        />
      )}
    </div>
  );
};

export default VideoInterview;
