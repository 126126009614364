import React, { useState,useEffect } from 'react';
import LoanApplicationsService from "../features/loanApplications/services/LoanApplicationsService";
import ScheduledCallsService from "../features/scheduledCalls/services/ScheduledCallsService";
import AuthService from '../features/auth/services/AuthService';
import Utils from '../features/loanApplications/Utils';
import CustomDatePicker from "../partials/CustomDatePicker";

function NewLoanApplication() {
  // States to manage form data for each tab
  const [products, setProducts] = useState([]);
  const [branches, setBranches] = useState([]);
  const [branchId, setBranchId] = useState(null);
  const [productId, setProductId] = useState(null);
  const [applicationId, setApplicationId] = useState(null);

  const [borrowerDetails, setBorrowerDetails] = useState({
    name: '',
    dob: '',
    mobile: '',
    email: '',
    borrower_photo: '66e2a6f41d4c33a00443e3b4',
  });
  const [coborrowers, setCoborrowers] = useState([]);
  const [coborrowerDetails, setCoborrowerDetails] = useState({
    name: 'Vishal N',
    dob: '10/05/1981',
    mobile: '9673173721',
    relation: 'Brother',
    gender: 'Male',
    occupation: 'Engineer'
  });
  const [loanDetails, setLoanDetails] = useState({
    customer_application_id: '123456',
    constitution: 'Trust',
    quantum: 0,
    business_model: 'Trading',
    industry: 'Textiles',
    tenure: '5',
    business_name: 'KV Tech',
    sole_owner: 'Yes',
    purpose: 'Working Capital',
    doi: '23/05/2023', // Default value in dd/mm/yyyy format
  });

  // State for active tab
  const [activeTab, setActiveTab] = useState(1);


  useEffect(() => {
    let config = localStorage.getItem('config')
    if(config){
      config = JSON.parse(config)
      setBranches(config.organisation.branches)
      setProducts(config.organisation.products)
    }
  }, []);
  
  // Tab navigation logic
  const handleTabChange = (tab) => {
    if (tab === 2 && !borrowerDetails.name) {
      alert('Please fill in the Borrower Details first.');
      return;
    }
    if (tab === 3 && !coborrowers.length) {
      alert('Please fill in the Co-Borrower Details first.');
      return;
    }
    setActiveTab(tab);
  };

  // Submit logic for Borrower Details (API call for Tab 1)
  const handleBorrowerSubmit = async (e) => {
    e.preventDefault()
    const payload = {
      "org_id": localStorage.getItem("org_id"),
      "source": "web",
      "global_id":localStorage.getItem("global_id"), // This is received from application init response
      "form": {
          "id": Utils.getFormId("application_init_form"),
          "data": {
              "application_details": {
                  "branch_id": branchId,
                  "product_id": productId
              },
              "borrower_details": borrowerDetails
          }
      }
  }

  const [newApplicationRes,error] = await LoanApplicationsService.create(payload)
  const fileUploadTokenRes = await AuthService.getToken()

  const callSchedulePayload = {
    "application_id": newApplicationRes.data.application.id,
    "mobile_number": borrowerDetails.mobile,
    "created_by": borrowerDetails.name,
    "applicant_id":  borrowerDetails.name,
    "applicant_name": borrowerDetails.name,
    "amount_required": loanDetails.quantum,
    "file_upload_token": fileUploadTokenRes.data.token,
    "global_id": fileUploadTokenRes.data.global_id,
    "org_id": localStorage.getItem("org_id"),
}

const callScheduleRes = await ScheduledCallsService.create(callSchedulePayload)

  window.location.href=`/loan-application-details?application_id=${newApplicationRes.data.application.id}`
  return;
  setApplicationId(newApplicationRes.data.application.id)


    setActiveTab(2);
  };

  // Submit logic for Co-Borrower Details (API call for Tab 2)
  const saveCoborrower = () => {
    if (coborrowerDetails.name && coborrowerDetails.dob && coborrowerDetails.mobile) {
      setCoborrowers([...coborrowers, { ...coborrowerDetails }]);
      setCoborrowerDetails({
        name: '',
        dob: '',
        mobile: '',
        relation: '',
        gender: '',
        occupation: ''
      });
    } else {
      alert("Please fill out all required fields.");
    }
  };

  
  const handleCoborrowerSubmit = async () => {

    const payload = {
      "id": applicationId,
      "form_id": Utils.getFormId("co_borrower_details"),
      "data":{
        "co_borrower":coborrowers
      }
  }
 await LoanApplicationsService.save(payload)
 
 

    setActiveTab(3);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoanDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Submit logic for Loan Details (API call for Tab 3)
  const handleLoanSubmit = async () => {
    const payload = {
      "id": applicationId,
      "form_id":  Utils.getFormId("loan_details"),
      "data":loanDetails
  }

  const data = await LoanApplicationsService.save(payload)
  //console.log(data)
  const fileUploadTokenRes = await AuthService.getToken()

  const callSchedulePayload = {
    "application_id": applicationId,
    "mobile_number": borrowerDetails.mobile,
    "created_by": borrowerDetails.name,
    "applicant_id":  borrowerDetails.name,
    "applicant_name": borrowerDetails.name,
    "amount_required": 0,
    "file_upload_token": fileUploadTokenRes.data.token,
    "global_id": fileUploadTokenRes.data.global_id,
    "org_id": localStorage.getItem("org_id"),
}
const handleDobChange = (e)=> {
  setBorrowerDetails({ ...borrowerDetails, dob: e.target.value })
}
const callScheduleRes = await ScheduledCallsService.create(callSchedulePayload)
  window.location.href="/"
  };

  return (
    <div className="w-full max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h3 className="text-xl font-semibold mb-4">New Loan Application</h3>
      <div className="tabs flex justify-between border-b">


      </div>

      {activeTab === 1 && (
        <div className="tab-content mt-4">
          <h4 className="font-semibold mb-4">Borrower Details</h4>
          <form onSubmit={handleBorrowerSubmit}>
          <div className="flex mb-4">
 
            <label className="w-1/4 text-gray-700 font-medium mr-4">Branch:</label>
            <select
        value={branchId}
        required
        onChange={(e) => setBranchId( e.target.value)}
        className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="">Select Branch</option>
        {branches?.map((branch) => (
          <option key={branch._id} value={branch._id}>
            {branch.name}  
          </option>
        ))}
      </select>

          </div>



          <div className="flex mb-4">
            <label className="w-1/4 text-gray-700 font-medium mr-4">Product:</label>
            <select
        value={productId}
        required
        onChange={(e) => setProductId( e.target.value)}
        className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="">Select Product</option>
        {products?.map((product) => (
          <option key={product._id} value={product._id}>
            {product.name} ({product.code})
          </option>
        ))}
      </select>
          </div>
          <div className="flex mb-4">
            <label className="w-1/4 text-gray-700 font-medium mr-4">Name:</label>
            <input
              type="text"
              required
              value={borrowerDetails.name}
              onChange={(e) => setBorrowerDetails({ ...borrowerDetails, name: e.target.value })}
              className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex mb-4">
            <label className="w-1/4 text-gray-700 font-medium mr-4">D.O.B.:</label>
            <CustomDatePicker onDateChange={function(e){setBorrowerDetails({ ...borrowerDetails, dob: e.target.value })}}/>
           
          </div>
          <div className="flex mb-4">
            <label className="w-1/4 text-gray-700 font-medium mr-4">Mobile Number:</label>
            <input
              required
              type="text"
              value={borrowerDetails.mobile}
              onChange={(e) => setBorrowerDetails({ ...borrowerDetails, mobile: e.target.value })}
              className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex mb-4">
            <label className="w-1/4 text-gray-700 font-medium mr-4">Email:</label>
            <input
            required
              type="text"
              value={borrowerDetails.email}
              onChange={(e) => setBorrowerDetails({ ...borrowerDetails, email: e.target.value })}
              className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mt-6 text-right">
          <button
           
            className="mt-6 px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Save and Continue
          </button>
          </div>
          </form>
        </div>
      )}

      {activeTab === 2 && (
 <div className="tab-content mt-4">
 <h4 className="font-semibold mb-4">Co-Borrower Details</h4>

 <div className="mt-6">
   <h5 className="font-semibold mb-4">Added Co-Borrowers:</h5>
   <ul>
     {coborrowers.map((coborrower, index) => (
       <li key={index} className="flex items-center justify-between p-3 border border-gray-300 rounded-lg mb-2">
         <div>
           <strong>{coborrower.name}</strong>
           <p>D.O.B: {coborrower.dob}</p>
           <p>Mobile: {coborrower.mobile}</p>
           <p>Relation: {coborrower.relation}</p>
           <p>Gender: {coborrower.gender}</p>
           <p>Occupation: {coborrower.occupation}</p>
         </div>
       </li>
     ))}
   </ul>
 </div>

 <div className="flex mb-4">
   <label className="w-1/4 text-gray-700 font-medium mr-4">Name:</label>
   <input
     type="text"
     value={coborrowerDetails.name}
     onChange={(e) => setCoborrowerDetails({ ...coborrowerDetails, name: e.target.value })}
     className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
   />
 </div>
 <div className="flex mb-4">
   <label className="w-1/4 text-gray-700 font-medium mr-4">D.O.B.:</label>
   <input
     type="text"
     value={coborrowerDetails.dob}
     onChange={(e) => setCoborrowerDetails({ ...coborrowerDetails, dob: e.target.value })}
     className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
   />
 </div>
 <div className="flex mb-4">
   <label className="w-1/4 text-gray-700 font-medium mr-4">Mobile Number:</label>
   <input
     type="tel"
     value={coborrowerDetails.mobile}
     onChange={(e) => setCoborrowerDetails({ ...coborrowerDetails, mobile: e.target.value })}
     className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
   />
 </div>
 <div className="flex mb-4">
   <label className="w-1/4 text-gray-700 font-medium mr-4">Relation:</label>
   <input
     type="text"
     value={coborrowerDetails.relation}
     onChange={(e) => setCoborrowerDetails({ ...coborrowerDetails, relation: e.target.value })}
     className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
   />
 </div>
 <div className="flex mb-4">
   <label className="w-1/4 text-gray-700 font-medium mr-4">Gender:</label>
   <input
     type="text"
     value={coborrowerDetails.gender}
     onChange={(e) => setCoborrowerDetails({ ...coborrowerDetails, gender: e.target.value })}
     className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
   />
 </div>
 <div className="flex mb-4">
   <label className="w-1/4 text-gray-700 font-medium mr-4">Occupation:</label>
   <input
     type="text"
     value={coborrowerDetails.occupation}
     onChange={(e) => setCoborrowerDetails({ ...coborrowerDetails, occupation: e.target.value })}
     className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
   />
 </div>
 
 <button
   onClick={saveCoborrower}
   className="mt-6 px-6 py-3 bg-yellow-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-yellow-500"
 >
   Add Co-Borrower
 </button>



 {coborrowers?.length!=0 &&<button
    onClick={handleCoborrowerSubmit}
   style={{float:'right'}}
    
   className="mt-6 px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
 >
   Save and Continue
 </button>}
</div>
      )}

      {activeTab === 3 && (
        <div className="tab-content mt-4">
      <h4 className="font-semibold mb-4">Business Details</h4>

      <div className="flex mb-4">
        <label className="w-1/4 text-gray-700 font-medium mr-4">Customer Application ID:</label>
        <input
          type="text"
          name="customer_application_id"
          value={loanDetails.customer_application_id}
          onChange={handleInputChange}
          className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="flex mb-4">
        <label className="w-1/4 text-gray-700 font-medium mr-4">Constitution:</label>
        <input
          type="text"
          name="constitution"
          value={loanDetails.constitution}
          onChange={handleInputChange}
          className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="flex mb-4">
        <label className="w-1/4 text-gray-700 font-medium mr-4">Quantum:</label>
        <input
          type="number"
          name="quantum"
          value={loanDetails.quantum}
          onChange={handleInputChange}
          className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="flex mb-4">
        <label className="w-1/4 text-gray-700 font-medium mr-4">Business Model:</label>
        <input
          type="text"
          name="business_model"
          value={loanDetails.business_model}
          onChange={handleInputChange}
          className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="flex mb-4">
        <label className="w-1/4 text-gray-700 font-medium mr-4">Industry:</label>
        <input
          type="text"
          name="industry"
          value={loanDetails.industry}
          onChange={handleInputChange}
          className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="flex mb-4">
        <label className="w-1/4 text-gray-700 font-medium mr-4">Tenure (in years):</label>
        <input
          type="number"
          name="tenure"
          value={loanDetails.tenure}
          onChange={handleInputChange}
          className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="flex mb-4">
        <label className="w-1/4 text-gray-700 font-medium mr-4">Business Name:</label>
        <input
          type="text"
          name="business_name"
          value={loanDetails.business_name}
          onChange={handleInputChange}
          className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="flex mb-4">
        <label className="w-1/4 text-gray-700 font-medium mr-4">Sole Owner:</label>
        <input
          type="text"
          name="sole_owner"
          value={loanDetails.sole_owner}
          onChange={handleInputChange}
          className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="flex mb-4">
        <label className="w-1/4 text-gray-700 font-medium mr-4">Purpose:</label>
        <input
          type="text"
          name="purpose"
          value={loanDetails.purpose}
          onChange={handleInputChange}
          className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="flex mb-4">
        <label className="w-1/4 text-gray-700 font-medium mr-4">Date of Incorporation </label>
        <input
          type="text"
          name="doi"
          placeholder='dd/mm/yyyy'
          value={loanDetails.doi}
          onChange={handleInputChange}
          className="w-3/4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mt-6 text-right">
      <button
        onClick={handleLoanSubmit}

        className="mt-6 px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        Save
      </button>
      </div>
    </div>
      )}
    </div>
  );
}

export default NewLoanApplication;
